import { acceptHMRUpdate, defineStore } from 'pinia'
import { ref } from 'vue'
import { PastaNotification, Paste } from '/@/interfaces'
import { usePasteListStore } from '/@/store/pasteList'
import getFilename from '/@/utils/files/getFilename'

interface AppState {
  [key: string]: any
}

export const useAppStateStore = defineStore('appState', (): AppState => {
  const pasteListStore = usePasteListStore()

  const mainElement = ref<HTMLElement | null>()
  const isConnected = ref(false)
  const showSignup = ref(false)
  const pasteListElement = ref(null)
  const appLoadingError = ref(null)
  const loadingOverlayProgress = ref(0)
  const hasClipboardWriteAccess = ref(false)
  const copyAutoSend = ref(false)
  const clipboardAutoReceive = ref(false)
  const currentSharePaste = ref()
  const currentMobileActionsPaste = ref()
  const allowClipboardAccess = ref(false)
  const allowPaste = ref(false)
  const pasteInputFocused = ref(false)
  const showMaintenanceMode = ref(false)
  const showDeleteUser = ref(false)
  const showAuthentication = ref(false)
  const showAppSettings = ref(false)
  const showLoadingOverlay = ref(false)
  const showShareQR = ref(false)
  const showLoginQR = ref(false)
  const showLogMeInQR = ref(false)
  const showQRScanner = ref(false)
  const autoReadEventListenerAttached = ref(false)
  const autoClipboardRead = ref(false)
  const autoDownload = ref(false)
  const showEncryptionKey = ref(false)
  const showEncryptionKeyInput = ref(false)
  const showCreateDevice = ref(false)
  const autoDownloadLocation = ref()
  const showNotifications = ref(false)
  const showMobilePasteActions = ref(false)
  const loadingPastes = ref(true)
  const pastePreview = ref<Blob | string | null>(null)
  const showPastePreview = ref(true)
  const isOfflineReady = ref(false)
  const isOfflineMode = ref(false)
  const isLoggingInWithQR = ref(false)
  const newestPaste = ref<Paste | null>(null)
  const windowFocused = ref(false)
  const showPwaUpdateButton = ref(false)
  const showUpdateOverlay = ref(false)
  const clickToCopy = ref(false)
  const pastePreviewElement = ref<HTMLElement | null | undefined>(null)
  const notification = ref<PastaNotification>()
  const devLogging = ref(false)
  const desktopUpdateDownloadProgress = ref()
  const desktopUpdateReady = ref(false)
  const isLoggingOut = ref(false)
  const showEmailVerification = ref(false)
  const showPasswordReset = ref(false)
  const showAskForEmailVerification = ref(false)
  const settingsOpen = ref(false)

  const setCurrentSharePaste = (pasteItem: Paste) => {
    const isText = pasteItem.contentType?.startsWith('text/')
    if (!isText) {
      // We dupe this because it will override the original paste and change
      // the filename displayed in the paste list
      const copiedPaste = JSON.parse(JSON.stringify(pasteItem))
      copiedPaste.filename = getFilename(pasteItem.content)
      currentSharePaste.value = copiedPaste
      return
    }
    // Get the paste from the paste list that's still encrypted, because in
    // the paste list the paste content remains encrypted.
    const paste = pasteListStore.pasteList.find(
      (paste: Paste) => paste.id === pasteItem.id,
    )
    if (paste) {
      currentSharePaste.value = paste
    }
  }

  return {
    mainElement,
    isConnected,
    showSignup,
    pasteListElement,
    appLoadingError,
    loadingOverlayProgress,
    hasClipboardWriteAccess,
    copyAutoSend,
    clipboardAutoReceive,
    currentSharePaste,
    currentMobileActionsPaste,
    allowClipboardAccess,
    allowPaste,
    pasteInputFocused,
    showDeleteUser,
    showMaintenanceMode,
    showAuthentication,
    showCreateDevice,
    showAppSettings,
    showLoadingOverlay,
    showShareQR,
    showLoginQR,
    showLogMeInQR,
    showQRScanner,
    autoReadEventListenerAttached,
    autoClipboardRead,
    autoDownload,
    showEncryptionKey,
    showEncryptionKeyInput,
    autoDownloadLocation,
    showNotifications,
    showMobilePasteActions,
    loadingPastes,
    pastePreview,
    showPastePreview,
    isOfflineReady,
    isOfflineMode,
    isLoggingInWithQR,
    newestPaste,
    windowFocused,
    showPwaUpdateButton,
    showUpdateOverlay,
    clickToCopy,
    pastePreviewElement,
    notification,
    devLogging,
    desktopUpdateDownloadProgress,
    desktopUpdateReady,
    isLoggingOut,
    showEmailVerification,
    showPasswordReset,
    showAskForEmailVerification,
    settingsOpen,
    setCurrentSharePaste,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useAppStateStore, import.meta.hot))
}
