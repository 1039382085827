import RTCConnection from '/@/services/RTCConnection'
import { databaseService, pasteActionsService } from '/@/services/index'
import devlog from '/@/utils/log'
import { reactive } from 'vue'
import { useDeviceStore } from '/@/store/deviceStore'
import { Device } from '/@/interfaces'

interface Connections {
  [key: string]: RTCConnection
}

class RTCService {
  connectionsRef: Connections = reactive({})
  stunCreds = null

  get connections() {
    return this.connectionsRef
  }

  set connections(connections: Connections) {
    this.connectionsRef = connections
  }

  constructor() {
    this.createConnection = this.createConnection.bind(this)
    this.onText = this.onText.bind(this)
    this.onFile = this.onFile.bind(this)
    this.onConnected = this.onConnected.bind(this)
    this.onConnecting = this.onConnecting.bind(this)
    this.onDisconnected = this.onDisconnected.bind(this)
    this.onConnectionStateChanged = this.onConnectionStateChanged.bind(this)
    this.onDownloadProgress = this.onDownloadProgress.bind(this)
    this.onUploadProgress = this.onUploadProgress.bind(this)
    this.onIceCandidate = this.onIceCandidate.bind(this)
    this.sendFiles = this.sendFiles.bind(this)
    this.sendText = this.sendText.bind(this)

    if (import.meta.hot) {
      import.meta.hot.accept(this.disconnectAll)
    }
  }

  async getStunCreds() {
    if (!this.stunCreds) {
      this.stunCreds = await databaseService.getStunCreds()
    } /* else {
      // TODO: these expire after a day... need to refresh them
      return this.stunCreds
    }*/
  }

  isDeviceConnectedViaRTC(deviceId: string) {
    const connection = this.connections[deviceId]
    if (!connection) return false
    return connection.connected
  }

  reconnectTimer() {
    setInterval(() => {
      const deviceStore = useDeviceStore()
      const devices = Object.values(deviceStore.onlineDevices)
      this.connectToDevices(devices)
    }, 5000)
  }

  connectToDevices(devices: Device[]) {
    const deviceStore = useDeviceStore()

    devices.forEach((device: Device) => {
      if (
        device.id &&
        device.id !== 'all' &&
        device.id !== deviceStore.thisDeviceId &&
        device.connected
      ) {
        console.log(device)
        this.createConnection(device.id)
      }
    })
  }

  handleOnlineStateChange(devices: Device[]) {
    for (const device of devices) {
      if (device.id && !device.connected && this.connections[device.id]) {
        this.deleteConnection(device.id)
      }
    }
  }

  async initConnection(deviceId: string): Promise<RTCConnection> {
    if (this.connections[deviceId]) return this.connections[deviceId]
    // const creds = await this.getStunCreds()
    this.connections[deviceId] = new RTCConnection(
      deviceId,
      this.stunCreds,
      this.onText,
      this.onFile,
      this.onConnecting,
      this.onConnected,
      this.onDisconnected,
      this.onConnectionStateChanged,
      this.onDownloadProgress,
      this.onUploadProgress,
      this.onIceCandidate,
    )
    return this.connections[deviceId]
  }

  async createConnection(deviceId: string) {
    try {
      devlog('service', 'rtc', `Creating new connection to ${deviceId}`)
      const connection = await this.initConnection(deviceId)

      if (connection.connected || connection.connecting) {
        devlog(
          'service',
          'rtc',
          `Connection already exists: ${deviceId}`,
          null,
          'warn',
        )
        return
      }
      const requestSpd = await connection.createConnection()
      if (!requestSpd) {
        devlog('service', 'rtc', `No request SPD: ${deviceId}`, null, 'warn')
        return
      }
      // const acceptSpd = await databaseService.initiateRTCConnection(
      //   requestSpd,
      //   deviceId,
      // )
      // if (!acceptSpd) {
      //   devlog('service', 'rtc', `No Accept SPD: ${deviceId}`, null, 'warn')
      //   return
      // }
      return requestSpd
      // await connection.acceptConnectionRequest(acceptSpd, deviceId)
      // await databaseService.removeRTCConnectionRequest(deviceId)
    } catch (error) {
      console.error(error)
      await databaseService.removeRTCConnectionRequest(deviceId)
    }
  }

  async joinConnection(
    sdp: string,
    deviceId: string,
  ): Promise<string | undefined> {
    let connection
    if (!this.connections[deviceId]) {
      connection = await this.initConnection(deviceId)
    } else {
      connection = this.connections[deviceId]
    }
    return await connection.joinConnection(sdp)
  }

  disconnectAll() {
    Object.values(this.connections).forEach((connection) => {
      this.deleteConnection(connection.peerId)
    })
  }

  addIceCandidate(ice: RTCIceCandidate, deviceId: string) {
    console.log(this.connections)
    const connection = this.connections[deviceId]
    if (!connection) return
    devlog('service', 'rtc', `Adding RTC ICE Candidate! from ${deviceId}`, ice)
    connection.peerConnection?.addIceCandidate(new RTCIceCandidate(ice))
  }

  onIceCandidate(ice: RTCIceCandidateInit, deviceId: string) {
    databaseService.sendIceCandidate(ice, deviceId)
  }

  async onConnected(deviceId: string) {
    await databaseService.removeRTCConnectionRequest(deviceId)
    devlog('service', 'rtc', `RTC Connected! ${deviceId}`)
  }

  onConnecting(deviceId: string) {
    devlog('service', 'rtc', `RTC Connecting... ${deviceId}`)
  }

  onDisconnected(deviceId: string) {
    this.deleteConnection(deviceId)
    devlog('service', 'rtc', `RTC Disconnected! ${deviceId}`)
  }

  onConnectionStateChanged(deviceId: string, connectionState: string) {
    const deviceStore = useDeviceStore()
    deviceStore.updateConnectedDevices(
      deviceId,
      connectionState === 'connected',
    )
    devlog(
      'service',
      'rtc',
      `RTC Connection State Changed! ${deviceId} : ${connectionState}`,
    )
  }

  onDownloadProgress(progress: number, deviceId: string) {
    devlog(
      'service',
      'rtc',
      `${deviceId} - RTC Download Progress: \n\n${progress}`,
    )
  }

  onUploadProgress(progress: number, deviceId: string) {
    devlog(
      'service',
      'rtc',
      `${deviceId} - RTC Upload Progress: \n\n${progress}`,
    )
  }

  onText(text: string, deviceId: string) {
    // alert(`RTC Text Received! ${deviceId}\n\n ${text}`)
    pasteActionsService.handleIncomingPasteFromRTC(text, deviceId)
    devlog('service', 'rtc', `RTC Text Received! ${deviceId}\n\n`, text)
  }

  onFile(file: File, deviceId: string) {
    devlog('service', 'rtc', `RTC File Received! ${deviceId}`, file)
  }

  sendFiles(files: File[], deviceId: string) {
    const connection = this.connections[deviceId]
    if (!connection) return
    connection.sendFiles(files)
  }

  sendText(text: string, deviceId: string) {
    const connection = this.connections[deviceId]
    if (!connection) return
    connection.sendText(text)
  }

  deleteConnection(deviceId: string) {
    console.log('disconnecting', deviceId)
    const deviceStore = useDeviceStore()
    this.connections[deviceId].destroy()
    delete this.connections[deviceId]
    deviceStore.updateConnectedDevices(deviceId, false)
    databaseService.removeRTCConnectionRequest(deviceId)
  }
}

export default new RTCService()
