import uploadFile from './firebase/firebaseUploader'
import devlog from './log'
import { databaseService, inAppNotificationService } from '../services'

import platform from '/@/utils/devices/platform'
import { useAppStateStore } from '/@/store/appState'

const getClipboardPermissionState = async (): Promise<string> => {
  console.log(platform)
  if (
    platform.browser.name === 'Firefox' ||
    platform.browser.name === 'Safari' ||
    platform.browser.name === 'SafariMobile'
  ) {
    return 'prompt'
  }
  const queryOpts = { name: 'clipboard-read' as PermissionName }
  const permissionStatus = await navigator.permissions.query(queryOpts)
  return permissionStatus.state
}

const checkClipboardPermission = async (): Promise<void> => {
  const appState = useAppStateStore()
  if (!appState.allowClipboardAccess) return
  const permissionStatus = await getClipboardPermissionState()
  devlog(
    'app',
    'Clipboard',
    'Got clipboard permission status →',
    permissionStatus.toUpperCase(),
  )
  if (navigator.clipboard && permissionStatus === 'granted') {
    appState.hasClipboardWriteAccess = true
  } else if (permissionStatus === 'prompt') {
    await inAppNotificationService.requestClipboardPermission()
  } else if (permissionStatus === 'denied') {
    await inAppNotificationService.notifyDeniedClipboardAccess()
  }
}

const getClipboardContents = async (): Promise<Blob[]> => {
  const files: Blob[] = []
  try {
    const clipboardItems = await navigator.clipboard.read()
    for (const clipboardItem of clipboardItems) {
      for (const type of clipboardItem.types) {
        const blob = await clipboardItem.getType(type)
        files.push(blob)
      }
    }
  } catch (err) {
    // devlog('app', 'Clipboard', 'Could not read clipboard contents', err)
  }
  return files
}

const writeImageToClipboard = async (data: File | Blob): Promise<void> => {
  if (!data) return
  await navigator.clipboard.write([new ClipboardItem({ 'image/png': data })])
}

const handlePaste = async (event: ClipboardEventInit): Promise<void> => {
  const clipboardFiles = event.clipboardData?.files
  if (clipboardFiles && clipboardFiles.length > 0) {
    devlog('app', 'Clipboard', 'Files Detected in paste')
    Array.from(clipboardFiles).forEach((file) => {
      uploadFile(file, file.type)
    })
  } else {
    const clipboardItems = await getClipboardContents()
    if (
      clipboardItems[0]?.type.startsWith('text') ||
      clipboardItems[1]?.type.startsWith('text')
    ) {
      devlog('app', 'Clipboard', 'Text Detected in paste')
      const text = await navigator.clipboard.readText()
      await databaseService.createPaste(text, 'text/string')
    } else {
      await checkClipboardPermission()
    }
  }
}

export {
  getClipboardPermissionState,
  getClipboardContents,
  writeImageToClipboard,
  checkClipboardPermission,
  handlePaste,
}
