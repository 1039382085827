import { animate } from 'motion'
import { percentage, percentageOf } from '../utils/math/mathUtils'
import delay from '../utils/delay'
import Timeout = NodeJS.Timeout
import devlog from '../utils/log'
import { useAppStateStore } from '/@/store/appState'

type DragTypeProperties = {
  [key: string]: {
    [key: string]: any
  }
}

const defaultDragTypeProperties = (): DragTypeProperties => {
  const appState = useAppStateStore()
  return {
    main: {
      element: appState.mainElement,
      currentTransform: 0,
      currentTransformPercent: 0,
    },
    pastePreview: {
      element: appState.pastePreviewElement,
      currentTransform: 0,
      currentTransformPercent: 0,
    },
  }
}

export class touchService {
  private lastX = 0
  private lastY = 0
  private startX = 0
  private direction = ''
  private isDragging = false
  private dragAmount = 0
  private isScrolling = false
  private currentTransform = 0
  private pasteListElement: HTMLElement | null = null
  private currentTransformPercent = 0

  private dragType = 'main'
  public dragTypeProperties?: DragTypeProperties

  get sidebarIsOpen() {
    return this.dragTypeProperties?.main.currentTransformPercent > 0
  }

  public constructDragTypeProperties(): void {
    this.dragTypeProperties = defaultDragTypeProperties()
  }

  public setStartX(e: TouchEvent): void {
    this.startX = e.changedTouches?.[0]?.clientX
  }

  public setCurrentTransform(): void {
    if (!this.dragTypeProperties?.[this.dragType]) return
    const element = this.dragTypeProperties[this.dragType].element
    const rawValue = getComputedStyle(element as HTMLElement).transform.split(
      ',',
    )[4]
    this.dragTypeProperties[this.dragType].currentTransform =
      parseFloat(rawValue)
  }

  public async handleTouchStart(
    e: TouchEvent,
    dragType: string,
  ): Promise<void> {
    const appState = useAppStateStore()
    this.dragType = dragType
    if (appState.showAuthentication) return
    if (
      !this.dragTypeProperties ||
      !this.dragTypeProperties.main.element ||
      !this.dragTypeProperties.pastePreview.element
    )
      this.constructDragTypeProperties()
    if (!this.pasteListElement) {
      const appState = useAppStateStore()
      this.pasteListElement = appState.pasteListElement
    }
    this.scrollStop()
    this.setStartX(e)
  }

  public async closeMenuOnTop(): Promise<void> {
    if (!this.sidebarIsOpen) return
    await delay(120)
    this.slideElement(0)
  }

  public handleTouchDrag(e: TouchEvent): void {
    const appState = useAppStateStore()
    const currentX = e.changedTouches?.[0]?.clientX

    if (currentX > this.lastX) {
      this.direction = 'right'
    } else if (currentX < this.lastX) {
      this.direction = 'left'
    }
    this.lastX = currentX

    this.dragAmount += 1
    this.isDragging = true
    if (this.isScrolling || this.dragAmount < 5) return
    if (appState.showAuthentication) return
    if (!this.dragTypeProperties?.[this.dragType]) return

    const transform =
      this.dragTypeProperties[this.dragType].currentTransform +
      currentX -
      this.startX
    this.dragTypeProperties[this.dragType].currentTransformPercent = percentage(
      transform,
      screen.width,
    )

    if (this.dragType === 'main') {
      this.moveMain(transform)
    } else if (this.dragType === 'pastePreview') {
      this.movePastePreview(transform)
    }
  }

  public handleTouchEnd(dragType: string): void {
    this.dragAmount = 0
    this.setCurrentTransform()

    if (dragType === 'main') {
      this.animateMain()
    } else if (dragType === 'pastePreview') {
      this.animatePastePreview()
    }

    this.isDragging = false
    this.isScrolling = false
  }

  public slideElement(translateX: number, el: null | HTMLElement = null) {
    if (!this.dragTypeProperties) return
    const element = el || this.dragTypeProperties[this.dragType].element
    animate(
      element,
      { transform: `translateX(${translateX}px)` },
      { duration: 0.2, allowWebkitAcceleration: true },
    ).finished
    this.dragTypeProperties[this.dragType].currentTransform = translateX
    this.dragTypeProperties[this.dragType].currentTransformPercent = percentage(
      translateX,
      screen.width,
    )
  }

  public moveMain(transform: number): void {
    const appState = useAppStateStore()
    if (!this.dragTypeProperties?.[this.dragType]) return
    const transformPercent =
      this.dragTypeProperties[this.dragType].currentTransformPercent
    if (
      (this.direction === 'right' || this.direction === 'left') &&
      transformPercent <= 80 &&
      transformPercent >= 0
    ) {
      ;(appState.mainElement as HTMLElement).style.transform =
        'translateX(' + transform + 'px)'
    }
  }

  public movePastePreview(transform: number): void {
    const appState = useAppStateStore()
    if (!this.dragTypeProperties?.[this.dragType]) return
    if (
      (this.direction === 'right' || this.direction === 'left') &&
      this.dragTypeProperties[this.dragType].currentTransformPercent >= 0
    ) {
      if (!appState.pastePreviewElement) return
      appState.pastePreviewElement.style.transform =
        'translateX(' + transform + 'px)'
    }
  }

  public animatePastePreview(): void {
    if (!this.dragTypeProperties?.[this.dragType]) return
    const maxRightPercent = percentageOf(110, screen.width)
    const transformPercent =
      this.dragTypeProperties[this.dragType].currentTransformPercent
    devlog('service', 'touchService', 'Transform Percent: ', transformPercent)
    if (transformPercent >= 25) {
      this.slideElement(maxRightPercent)
    }

    if (transformPercent < 25) {
      this.slideElement(0)
    }
  }

  public animateMain(): void {
    const appState = useAppStateStore()
    if (!this.dragTypeProperties?.[this.dragType]) return
    const maxRightPercent = percentageOf(80, screen.width)
    const transformPercent =
      this.dragTypeProperties[this.dragType].currentTransformPercent
    // Swiping left and reaching hide sidebar threshold: return to left 0
    // pos (closing the sidebar)
    if (this.direction === 'left' && transformPercent <= 65) {
      this.slideElement(0)
      appState.showPastePreview = false
    }

    // While Menu is open but swiping left not enough to close it again.
    if (this.direction === 'left' && transformPercent >= 65) {
      this.slideElement(maxRightPercent)
      appState.showPastePreview = true
    }

    // Swiping right and reaching show sidebar threshold: go to max right
    // pos (opening the sidebar)
    if (
      this.direction === 'right' &&
      transformPercent >= 30 &&
      transformPercent <= 65
    ) {
      this.slideElement(maxRightPercent)
      appState.showPastePreview = false
    }

    // Swiping right but not reaching show sidebar threshold: return to left 0
    // pos
    if (this.direction === 'right' && transformPercent <= 30) {
      this.slideElement(0)
      appState.showPastePreview = true
    }
  }

  public scrollStop(refresh = 66): void {
    // Sets whether the user is scrolling or not.
    if (this.pasteListElement?.getAttribute('scrollListening') === 'true')
      return

    let scrollingTimer: Timeout

    this.pasteListElement?.addEventListener(
      'scroll',
      () => {
        this.isScrolling = true
        window.clearTimeout(scrollingTimer)

        scrollingTimer = setTimeout(() => {
          if (this.isScrolling && this.isDragging) return
          this.isScrolling = false
        }, refresh)
      },
      false,
    )
    this.pasteListElement?.setAttribute('scrollListening', 'true')
  }
}

export default new touchService()
