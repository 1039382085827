import {
  child,
  connectDatabaseEmulator,
  get,
  getDatabase,
  onValue,
  push,
  ref,
  remove,
  set,
  update,
} from 'firebase/database'
import {
  connectFunctionsEmulator,
  type Functions,
  getFunctions,
  httpsCallable,
  type HttpsCallableResult,
} from 'firebase/functions'
import { deleteObject, getStorage, ref as storageRef } from 'firebase/storage'
import nanoId, { nanoIdSecure } from '/@/utils/generateNanoId'
import devlog from '/@/utils/log'
import { PastaLocalStorageKeys } from '../../../../types'
import { getDeviceName } from '/@/utils/devices/getDeviceName'
import {
  Device,
  Paste,
  QRLoginData,
  QRLoginStrings,
  WebRTCConnectionAccept,
  WebRTCConnectionRequest,
} from '/@/interfaces'
import {
  idbService,
  ipcService,
  pasteActionsService,
  pwaService,
  RTCService,
  sealdService,
} from '/@/services/index'
import { getSettings } from '/@/utils/settings/getSettings'
import isDesktop from '/@/utils/isDesktop'
import { getRandomColor } from '/@/utils/getRandomColor'
import { getAuth } from 'firebase/auth'
import { getFileType } from '/@/utils/files/getFileType'
import delay from '/@/utils/delay'
import { Devices, useDeviceStore } from '/@/store/deviceStore'
import { useUserStore } from '/@/store/user'
import { useAppStateStore } from '/@/store/appState'
import { getApp } from 'firebase/app'
import endpoints from '/@/config/endpoints'
import getFileURL from '/@/utils/firebase/getFileURL'
import firebaseConfig from '/@/config/firebaseConfig'
import loginService from '/@/services/loginService'
import { Unsubscribe } from '@firebase/database'

interface Database {
  app: any
  type: any
}

enum FunctionNames {
  CREATE_USER = 'createUserFunction',
  GET_JWT = 'createSealdJWT',
  CREATE_SHARE_LINK = 'createShareLink',
  GET_QR_LOGIN_TOKEN = 'createQRLoginToken',
  STORE_QR_LOGIN_TOKEN = 'storeQRLoginToken',
  GET_QR_LOGIN_DATA = 'getQRLoginData',
  DELETE_QR_LOGIN_DATA = 'deleteQRLoginData',
  GET_STUN_CREDS = 'getStunCreds',
}

class databaseService {
  db: Database | undefined
  devicesSetUp = false
  functions: Functions | undefined
  existingQrLoginToken: string | undefined
  DBQRChangeListener: Unsubscribe | undefined
  deviceListener: Unsubscribe | undefined
  userListener: Unsubscribe | undefined

  init() {
    if (this.db && this.functions) return
    const app = getApp()
    this.db = getDatabase(app, firebaseConfig.databaseURL)
    this.functions = getFunctions(app, 'europe-west1')
    if (import.meta.env.VITE_DEV_EMULATORS) {
      // Emulator
      connectFunctionsEmulator(this.functions, '127.0.0.1', 5001)
      connectDatabaseEmulator(this.db, '127.0.0.1', 9000)
      // Emulator
    }
  }

  async getUser() {
    this.init()
    if (!this.db) return
    const dbRef = ref(this.db)
    try {
      const userStore = useUserStore()
      const user = await get(child(dbRef, `users/${userStore.userId}`))
      if (user.exists()) {
        devlog('init', 'Firebase', 'Got User', user.val())
        return user.val()
      }
    } catch (error) {
      console.error(error)
    }
  }

  async getDevices(): Promise<Devices | undefined> {
    this.init()
    if (!this.db) return
    const dbRef = ref(this.db)
    try {
      const userStore = useUserStore()
      const devices = await get(child(dbRef, `devices/${userStore.userId}`))
      if (devices.exists()) {
        devlog('init', 'Firebase', 'Got Devices', devices.val())
        return devices.val()
      }
    } catch (error) {
      console.error(error)
    }
  }

  async getJWT() {
    this.init()
    if (!this.functions) return
    const auth = getAuth()
    try {
      const getJWTFunction = httpsCallable(
        this.functions,
        FunctionNames.GET_JWT,
      )
      const request = (await getJWTFunction({
        userId: auth.currentUser?.uid,
      })) as HttpsCallableResult<{
        jwt: string
        status?: number
        email?: string
        error?: string
      }>
      // if (request.status !== 200) new Error('Error getting JWT')
      const { jwt } = request.data
      devlog('init', 'Firebase', 'Got user JWT')
      return jwt
    } catch (error) {
      console.error(error)
    }
  }

  async transmitLogMeInSession(path: string, key: string) {
    devlog('service', 'Firebase', 'Generating QR Login')
    this.init()
    if (!this.functions || !this.db) return
    try {
      const serializedId = await sealdService.exportIdentity()
      const getFirebaseCustomLoginToken = httpsCallable(
        this.functions,
        FunctionNames.GET_QR_LOGIN_TOKEN,
      )
      const response =
        (await getFirebaseCustomLoginToken()) as HttpsCallableResult<{
          qrToken: string
        }>
      const { qrToken: fireBaseToken } = response.data

      const qrDataString = JSON.stringify({
        t: fireBaseToken,
        s: JSON.stringify(serializedId),
      })

      const encryptedQrData = (await sealdService.encryptMessage(
        qrDataString,
      )) as string
      const symEncKeyId = (await sealdService.addSymEncKeyToSession(
        key,
      )) as string

      const encryptionInfo = {
        sessionId: sealdService.session?.sessionId as string,
        symEncKeyId,
      }

      const finalData: QRLoginData = {
        qrData: encryptedQrData,
        encryptionInfo,
      }

      const listenedOnLogMeInRef = ref(this.db, `qrLogins/${path}`)
      await set(listenedOnLogMeInRef, finalData)
    } catch (error) {
      console.error(error)
    }
  }

  async generateQrLoginToken() {
    if (this.existingQrLoginToken) return this.existingQrLoginToken
    devlog('service', 'Firebase', 'Generating QR Login')
    this.init()
    if (!this.functions) return
    try {
      const key = nanoIdSecure()
      const serializedId = await sealdService.exportIdentity()
      const generateQrLoginToken = httpsCallable(
        this.functions,
        FunctionNames.GET_QR_LOGIN_TOKEN,
      )
      const response = (await generateQrLoginToken()) as HttpsCallableResult<{
        qrToken: string
      }>
      const { qrToken } = response.data

      ////

      const qrDataString = JSON.stringify({
        t: qrToken,
        s: JSON.stringify(serializedId),
      })

      const encryptedQrData = await sealdService.encryptMessage(qrDataString)
      const symEncKeyId = await sealdService.addSymEncKeyToSession(key)

      const encryptionInfo = {
        sessionId: sealdService.session?.sessionId,
        symEncKeyId,
      }

      const storeQrLoginToken = httpsCallable(
        this.functions,
        FunctionNames.STORE_QR_LOGIN_TOKEN,
      )
      const storeResponse = (await storeQrLoginToken({
        qrData: encryptedQrData,
        encryptionInfo,
      })) as HttpsCallableResult<{ path: string }>

      const { path } = storeResponse.data

      this.existingQrLoginToken = `${path}${QRLoginStrings.KeyPathSeparator}${key}`
      return this.existingQrLoginToken
    } catch (error) {
      console.error(error)
    }
  }

  async getQrLoginData(pathId: string) {
    this.init()
    if (!this.functions) throw new Error('Functions not initialized!')
    try {
      const getQRLoginDataFunction = httpsCallable(
        this.functions,
        FunctionNames.GET_QR_LOGIN_DATA,
      )
      const request = (await getQRLoginDataFunction({
        pathId,
      })) as HttpsCallableResult<{
        qrLoginData: {
          qrData: string
          encryptionInfo: {
            sessionId: string
            symEncKeyId: string
          }
        }
      }>
      const { qrLoginData } = request.data
      return qrLoginData
    } catch (error) {
      console.error(error)
    }
  }

  async deleteQrLoginData(pathId: string) {
    this.init()
    if (!this.functions) throw new Error('Functions not initialized!')
    try {
      const deleteQRLoginDataFunction = httpsCallable(
        this.functions,
        FunctionNames.DELETE_QR_LOGIN_DATA,
      )
      await deleteQRLoginDataFunction({
        pathId,
      })
    } catch (error) {
      console.error(error)
    }
  }

  async createUser() {
    this.init()
    if (!this.functions) throw new Error('Functions not initialized!')
    try {
      const createUserFunction = httpsCallable(
        this.functions,
        FunctionNames.CREATE_USER,
      )
      const dbKey = sealdService.generateDBKey()
      const request = (await createUserFunction({
        dbKey,
      })) as unknown as any
      if (request.status !== 200) new Error('Error creating user')
      devlog('init', 'Firebase', 'Created new user', request.data)
    } catch (error) {
      console.error(error)
    }
  }

  async updateUser(parameters: { [key: string]: any } | null = null) {
    if (!this.db) return
    const userStore = useUserStore()
    try {
      await update(ref(this.db, `users/${userStore.userId}`), {
        settings: getSettings(),
        updatedAt: new Date(),
        ...parameters,
      })
      devlog('service', 'Firebase', 'Updated User')
    } catch (error) {
      console.error(error)
    }
  }

  async editDevice(device: Device) {
    if (!this.db) return
    const userStore = useUserStore()
    try {
      await update(ref(this.db, `devices/${userStore.userId}/${device.id}`), {
        ...device,
      })
      devlog('service', 'Firebase', 'Updated device')
    } catch (error) {
      console.error(error)
    }
  }

  async createPaste(
    content: string,
    type: string,
    overwritePasteData: Partial<Paste> = {},
  ) {
    const deviceStore = useDeviceStore()
    const userStore = useUserStore()
    const { thisDeviceId, selectedDevice, selectedDeviceId } = deviceStore
    if (!this.db || !userStore.userId || !thisDeviceId) return
    const encryptedContent = await sealdService.encryptMessage(content)
    const id = overwritePasteData.id ? overwritePasteData.id : nanoId()
    const paste = {
      id,
      content: encryptedContent,
      contentType: type || 'text/string',
      device: getDeviceName(),
      deviceId: deviceStore.thisDeviceId,
      fcmId: deviceStore.thisFcmId || '',
      userId: userStore.userId,
      createdAt: new Date().toISOString(),
      ...overwritePasteData,
    }
    devlog('app', 'Paste', 'Creating new Paste')
    try {
      if (
        selectedDevice.connected &&
        RTCService.connections[selectedDeviceId]
      ) {
        RTCService.connections[selectedDeviceId].sendText(JSON.stringify(paste))
      } else {
        await push(
          ref(
            this.db,
            `devices/${userStore.userId}/${deviceStore.selectedDeviceId}/pastes`,
          ),
          paste,
        )
      }
    } catch (error) {
      console.error(error)
    }
  }

  async deletePaste(paste: Paste) {
    if (!this.db) return

    try {
      // Delete file from storage if it is a file
      if (
        getFileType(paste.contentType) !== 'string' &&
        paste.content.split('/').length === 4
      ) {
        const app = getApp()
        const storage = getStorage(app)
        const fileRef = storageRef(storage, paste.content)
        await deleteObject(fileRef)
        devlog('service', 'Firebase', 'Deleted Paste File', paste.id)
      }
    } catch (error) {
      console.error(error)
    }

    await delay(80)

    try {
      // Delete paste reference in RTDB
      await remove(
        ref(
          this.db,
          `devices/${paste.userId}/${paste.deviceId}/pastes/${paste.rtdbId}`,
        ),
      )
      devlog('service', 'Firebase', 'Deleted Paste', paste.id)
    } catch (error) {
      console.error(error)
    }
  }

  async setupDevices(devices: Devices) {
    const appState = useAppStateStore()
    if (appState.isLoggingOut) return
    const deviceStore = useDeviceStore()
    const deviceIds = Object.keys(deviceStore.devices)

    try {
      if (deviceIds?.length) {
        devlog('service', 'Firebase', 'Got Rooms', devices)
        deviceStore.devices = devices
        appState.isConnected = true
        pwaService.pwaReceiveShare()

        // START - Maybe move this to startupService?
        const localSelectedDeviceId = localStorage.getItem(
          PastaLocalStorageKeys.SELECTED_DEVICE_ID,
        )
        const currentDeviceIdLocalIsInStore = deviceIds.includes(
          localSelectedDeviceId || '',
        )
        if (localSelectedDeviceId && currentDeviceIdLocalIsInStore) {
          deviceStore.setActiveDevice(localSelectedDeviceId)
        } else {
          deviceStore.setActiveDevice('all')
        }
        this.devicesSetUp = true
        // END - Maybe move this to startupService?

        if (isDesktop) ipcService.updateTrayMenuConnectedStatus('connected')
        if (appState.isOfflineMode) return
        appState.loadingPastes = false
        await idbService.storePastaData(devices)
      } else {
        console.error('Something went wrong here, there are no rooms?!')
      }
    } catch (error) {
      console.error(error)
    }
    this.devicesSetUp = true
  }

  async createDevice(
    title: string,
    type: string,
    fcmId: string,
  ): Promise<Device | null> {
    this.init()
    const appState = useAppStateStore()
    const deviceStore = useDeviceStore()
    const userStore = useUserStore()
    if (!this.db || !userStore.userId || appState.isLoggingOut) {
      return null
    }

    try {
      const key = push(ref(this.db, `devices/${userStore.userId}/`)).key

      if (!key) {
        return null
      }

      const device: Device = {
        id: key,
        userId: userStore.userId,
        title,
        type,
        fcmId,
        connected: true,
        settings: { color: getRandomColor() },
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString(),
      }

      await update(ref(this.db, `devices/${userStore.userId}/${key}`), device)

      deviceStore.setActiveDevice(key)
      localStorage.setItem(PastaLocalStorageKeys.SELECTED_DEVICE_ID, key)
      devlog('service', 'Firebase', 'Created Room', name)
      return device
    } catch (error) {
      console.error(error)
      return null
    }
  }

  // Unused
  async deleteDevice(id: string) {
    // TODO: Adapt this to device
    if (!this.db) return
    const userStore = useUserStore()
    try {
      await remove(ref(this.db, `devices/${userStore.userId}/${id}`))
      devlog('service', 'Firebase', 'Deleted Room', id)
    } catch (error) {
      console.error(error)
    }
  }

  // This is where incoming pastes are handled
  async listenForDeviceChanges() {
    if (!this.db || this.deviceListener) return
    const deviceStore = useDeviceStore()
    const userStore = useUserStore()
    const appState = useAppStateStore()
    const roomsRef = ref(this.db, `devices/${userStore.userId}`)
    this.deviceListener = onValue(roomsRef, async (snapshot) => {
      if (appState.isLoggingOut) return
      const data = snapshot.val()
      if (!data) {
        // Creates room if there aren't any rooms for current user.
        // await this.createRoom(this.defaultRoomName as string)
        // Create first example paste.
        // await this.createPaste(
        //   "Welcome to Pasta! Here's a paste",
        //   'text/string',
        //   { deviceId: 'NULL_ID' },
        // )
      } else if (!this.devicesSetUp) {
        await this.setupDevices(data)
      } else {
        deviceStore.devices = data
        await pasteActionsService.handleIncomingPasteFromFirebase(data)
        await idbService.storePastaData(data)
        RTCService.connectToDevices(deviceStore.onlineDevices)
        RTCService.handleOnlineStateChange(Object.values(data))
      }
    })
  }

  async listenForUserChanges() {
    if (!this.db || this.userListener) return
    const userStore = useUserStore()
    const appState = useAppStateStore()
    const userRef = ref(this.db, `users/${userStore.userId}`)
    this.userListener = onValue(userRef, async (snapshot) => {
      if (appState.isLoggingOut) return
      const data = snapshot.val()
      if (!data?.email) return
      devlog('init', 'Firebase', 'User was updated', data)
      userStore.user = data
    })
  }

  public async createShareLink(paste: Paste): Promise<string | undefined> {
    this.init()
    if (!this.functions) return
    const userStore = useUserStore()
    const key = sealdService.generateShareKey()
    const isText = paste.contentType.startsWith('text/')

    let symEncKeyId

    try {
      symEncKeyId = await sealdService.addSymEncKeyToSession(key)

      const createShareLinkFunction = httpsCallable(
        this.functions,
        FunctionNames.CREATE_SHARE_LINK,
      )

      // If it's a file/image, we encrypt the public URL gotten from here
      // and replace content with it.
      if (!isText) {
        const url = await getFileURL(paste.content)
        const encryptedUrl = await sealdService.encryptMessage(url)
        if (!encryptedUrl) {
          console.error('Error encrypting file URL')
          return
        }
        paste.content = encryptedUrl
      }

      const desiredPasteData = {
        id: paste.id,
        content: paste.content,
        contentType: paste.contentType,
        createdAt: paste.createdAt,
        device: paste.device,
        userName: userStore.userName,
        encryptionInfo: {
          sessionId: sealdService.session?.sessionId,
          symEncKeyId,
        },
        filename: paste.filename ? paste.filename : '',
      }

      const request = (await createShareLinkFunction({
        ...desiredPasteData,
      })) as HttpsCallableResult<{ shareId: string }>

      devlog('service', 'Firebase', 'Created share link', request.data.shareId)

      return `${endpoints.share}${request.data.shareId}/#${key}`
    } catch (error) {
      console.error(error)
      if (symEncKeyId) {
        await sealdService.deleteSymEncKeyFromSession(symEncKeyId)
      }
    }
  }

  // Handles the desktop QR login flow (display QR on desktop to scan with
  // mobile to log in)
  listenForLogMeInQRLoginStateChanges(pathId: string, key: string) {
    this.init()
    if (!this.db || this.DBQRChangeListener) return
    const userRef = ref(this.db, `qrLogins/${pathId}`)
    this.DBQRChangeListener = onValue(userRef, async (snapshot) => {
      const data = snapshot.val() as QRLoginData
      if (data === null) return
      if (!data.qrData || !data.encryptionInfo) {
        return
      }
      await loginService.executeLogMeInQR(data, key, pathId)
      // After success unsubscribe from listener
      this.DBQRChangeListener?.()
      this.DBQRChangeListener = undefined
    })
  }

  async initiateRTCConnection(
    sdpString: string,
    deviceId: string,
  ): Promise<string | undefined> {
    if (!this.db) return
    let responseListener: Unsubscribe | undefined
    const userStore = useUserStore()
    const deviceStore = useDeviceStore()
    const { thisDeviceId } = deviceStore
    if (!thisDeviceId) {
      console.warn(
        'No current device ID, not listening for RTC Connection Requests',
      )
      return
    }

    try {
      const requestRef = ref(
        this.db,
        `rtcConnectionRequests/${userStore.userId}/${deviceId}/offer`,
      )
      await set(requestRef, {
        sdp: sdpString,
        requestingDeviceId: thisDeviceId,
        time: new Date().toISOString(),
      })

      devlog('service', 'Firebase', 'Created RTC Connection Request')

      const acceptRef = ref(
        this.db,
        `rtcConnectionRequests/${userStore.userId}/${deviceId}/accept`,
      )
      return new Promise((resolve, reject) => {
        responseListener = onValue(acceptRef, async (snapshot) => {
          const data: WebRTCConnectionAccept = snapshot.val()
          if (!data) return
          const { sdp } = data
          if (sdp) {
            responseListener?.()
            resolve(sdp)
          } else {
            reject()
          }
        })
      })
    } catch (error) {
      console.error(error)
      await this.removeRTCConnectionRequest(thisDeviceId)
    }
  }

  async listenForRTCICECandidates() {
    if (!this.db) return
    const userStore = useUserStore()
    const deviceStore = useDeviceStore()
    const appState = useAppStateStore()
    const { thisDeviceId } = deviceStore
    if (!thisDeviceId) {
      console.warn(
        'No current device ID, not listening for RTC Connection Requests',
      )
      return
    }
    const iceRef = ref(
      this.db,
      `rtcConnectionRequests/${userStore.userId}/${thisDeviceId}/iceCandidates`,
    )
    onValue(iceRef, async (snapshot) => {
      try {
        if (appState.isLoggingOut) return
        const data: /*WebRTCConnectionRequest*/ any = snapshot.val()

        if (!data || !data.ice) return

        devlog('service', 'Firebase', 'Got RTC IceCandidate from remote', data)
        const { ice, requestingDeviceId } = data

        if (!this.db || requestingDeviceId === thisDeviceId) return

        RTCService.addIceCandidate(ice, requestingDeviceId)
      } catch (e) {
        console.error(e)
        await this.removeRTCConnectionRequest(thisDeviceId)
      }
    })
  }

  async sendIceCandidate(ice: RTCIceCandidateInit, deviceId: string) {
    if (!this.db) return

    const userStore = useUserStore()
    const deviceStore = useDeviceStore()

    const iceRef = ref(
      this.db,
      `rtcConnectionRequests/${userStore.userId}/${deviceId}/iceCandidates`,
    )

    await set(iceRef, {
      ice: ice,
      requestingDeviceId: deviceStore.thisDeviceId,
      time: new Date().toISOString(),
    })
    devlog('service', 'rtc', `Sent RTC ICE Candidate to ${deviceId}`, ice)
  }

  async listenForRTCConnectionRequests() {
    if (!this.db) return
    const userStore = useUserStore()
    const deviceStore = useDeviceStore()
    const appState = useAppStateStore()
    const { thisDeviceId } = deviceStore
    if (!thisDeviceId) {
      console.warn(
        'No current device ID, not listening for RTC Connection Requests',
      )
      return
    }
    const connectionRef = ref(
      this.db,
      `rtcConnectionRequests/${userStore.userId}/${thisDeviceId}/offer`,
    )
    onValue(connectionRef, async (snapshot) => {
      try {
        if (appState.isLoggingOut) return
        const data: WebRTCConnectionRequest = snapshot.val()

        if (!data) return

        const { sdp, requestingDeviceId } = data
        devlog(
          'service',
          'Firebase',
          'Got RTC Request from',
          requestingDeviceId,
        )

        if (!this.db || requestingDeviceId === thisDeviceId) return
        console.log(new Date().getTime())
        const acceptSpd = await RTCService.joinConnection(
          sdp,
          requestingDeviceId,
        )
        console.log(new Date().getTime())
        const requestRef = ref(
          this.db,
          `rtcConnectionRequests/${userStore.userId}/${thisDeviceId}/accept`,
        )

        await set(requestRef, {
          sdp: acceptSpd,
          requestingDeviceId: thisDeviceId,
          time: new Date().toISOString(),
        })
        devlog(
          'service',
          'Firebase',
          'Sent join request to ',
          requestingDeviceId,
        )
      } catch (e) {
        console.error(e)
        await this.removeRTCConnectionRequest(thisDeviceId)
      }
    })
  }

  async removeRTCConnectionRequest(deviceId: string) {
    return
    if (!this.db) return
    const userStore = useUserStore()
    try {
      await remove(
        ref(this.db, `rtcConnectionRequests/${userStore.userId}/${deviceId}`),
      )
    } catch (error) {
      console.error(error)
    }
  }

  async getStunCreds() {
    this.init()
    if (!this.functions) return
    const stunCredsRequest = httpsCallable(
      this.functions,
      FunctionNames.GET_STUN_CREDS,
    )
    const response = await stunCredsRequest()
    console.log(response)
    const { urls, username, credential } = response.data.iceServers

    return {
      iceServers: urls.map((url: string) => {
        if (url.startsWith('stun:')) {
          return { urls: url }
        } else {
          return { urls: url, username, credential }
        }
      }),
    }
  }

  async testFunction(functionName: string) {
    devlog('service', 'Firebase', 'Testing function: ' + functionName)
    this.init()
    if (!this.functions) return
    const testFunction = httpsCallable(this.functions, functionName)
    const response = await testFunction()
    console.info(response)
  }

  async testMethod(arg: any) {
    // return this.getJWT()
    return await sealdService.importIdentity(arg)
  }
}

export default new databaseService()
