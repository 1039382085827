import { Enumify } from '/@/interfaces'
import devlog from '/@/utils/log'
import { calcTransferRate } from '/@/utils/math/mathUtils'

const uncapSdpSpeed = (sdp: string) => {
  try {
    return sdp.replace('b=AS:30', 'b=AS:1638400')
  } catch (e) {
    return sdp
  }
}

const createOfferFromSdp = (sdp: string): RTCSessionDescriptionInit => {
  return {
    type: 'offer',
    sdp: sdp,
  }
}

const createAnswerFromSdp = (sdp: string): RTCSessionDescriptionInit => {
  return {
    type: 'answer',
    sdp: sdp,
  }
}

export const RTC_MESSAGE_TYPES = {
  HEADER: 'header',
  PART: 'partition',
  PART_RECEIVED: 'partition-received',
  PROGRESS: 'progress',
  TRANSFER_COMPLETE: 'transfer-complete',
  TEXT: 'text',
} as const

interface RTCReceivedFile {
  name: string
  size: number
  mime: string
  blob: Blob
}

interface RTCMessage extends RTCReceivedFile {
  type: Enumify<typeof RTC_MESSAGE_TYPES>
  text: string
  progress: number
}

// The flow of creating a connection is follows the form of a handshake, Follow the numbered execution steps 1, 2 and 3 (Bob and Alice being separate clients).
// 1. Bob creates the connection request, sends that signal (`this.peerConnection.localDescription.sdp` from his client) to Alice (typically via websocket).
// 2. Alice receives the connection request, and joins it by sending back an accept signal (`this.peerConnection.localDescription.sdp` from her client)
// 3. Bob receives Alice's accept signal on his client and completes the connection by applying `(answerSdp: string)` to his `this.peerConnection`
// 4. Bob and or Alice can now send each other messages with their respective `this.channel` instances.

class RTCConnection {
  peerConnection: RTCPeerConnection | undefined
  channel: RTCDataChannel | undefined
  peerId: string
  _busy: boolean = false
  _filesQueue: File[] = []
  _chunker: FileChunker | undefined
  _digester: FileDigester | undefined
  _lastProgress = 0
  _reader: FileReader | undefined
  // Callbacks
  onText: (text: string, peerId: string) => void
  onFile: (file: File, peerId: string) => void
  onConnecting: (peerId: string) => void
  onConnected: (peerId: string) => void
  onDisconnected: (peerId: string) => void
  onConnectionStateChanged: (peerId: string, connectionState: string) => void
  onDownloadProgress: (progress: number, peerId: string) => void
  onUploadProgress: (progress: number, peerId: string) => void
  onIceCandidate: (ice: RTCIceCandidateInit, peerId: string) => void

  constructor(
    peerId: string,
    rtcConfig: RTCConfiguration,
    onText: (text: string, peerId: string) => void,
    onFile: (file: File, peerId: string) => void,
    onConnecting: (peerId: string) => void,
    onConnected: (peerId: string) => void,
    onDisconnected: (peerId: string) => void,
    onConnectionStateChanged: (peerId: string, connectionState: string) => void,
    onDownloadProgress: (progress: number, peerId: string) => void,
    onUploadProgress: (progress: number, peerId: string) => void,
    onIceCandidate: (ice: RTCIceCandidateInit, peerId: string) => void,
  ) {
    this.peerId = peerId
    this.onText = onText
    this.onFile = onFile
    this.onConnecting = onConnecting
    this.onConnected = onConnected
    this.onDisconnected = onDisconnected
    this.onConnectionStateChanged = onConnectionStateChanged
    this.onDownloadProgress = onDownloadProgress
    this.onUploadProgress = onUploadProgress
    this.onIceCandidate = onIceCandidate
    this.peerConnection = new RTCPeerConnection(rtcConfig)
    console.log(rtcConfig)
  }

  get connected() {
    // "closed" | "connected" | "connecting" | "disconnected" | "failed" | "new";
    return this.peerConnection?.connectionState === 'connected'
  }

  get connecting() {
    return this.peerConnection?.connectionState === 'connecting'
  }

  get stable() {
    return this.peerConnection?.signalingState === 'stable'
  }

  get closed() {
    return this.peerConnection?.connectionState === 'closed'
  }

  get disconnected() {
    return this.peerConnection?.connectionState === 'disconnected'
  }

  get failed() {
    return this.peerConnection?.connectionState === 'failed'
  }

  // 1. Bob
  async createConnection(): Promise<string | undefined> {
    if (!this.peerConnection) return
    this.channel = this.peerConnection.createDataChannel('chat')

    await this.peerConnection.setLocalDescription(
      await this.peerConnection.createOffer(),
    )

    // This runs when after connection achieved
    this.peerConnection.onconnectionstatechange = () => {
      if (!this.peerConnection) return
      this.onConnectionStateChanged(
        this.peerId,
        this.peerConnection.connectionState,
      )
      if (this.peerConnection.connectionState === 'connected' && this.channel) {
        // Connected!
        this.onConnected(this.peerId)
      } else if (
        this.peerConnection.connectionState === 'disconnected' ||
        this.peerConnection.connectionState === 'closed' ||
        this.peerConnection.connectionState === 'failed'
      ) {
        // Disconnected!
        this.onDisconnected(this.peerId)
      }
    }

    this.channel.onopen = () => {
      devlog('service', 'rtc', 'Data channel open!')
    }

    // This runs when receiving a message
    this.channel.onmessage = (event: MessageEvent<string>) => {
      // You have the message!
      this._onMessage(event.data)
    }

    this.peerConnection.onicecandidate = ({ candidate }) => {
      if (candidate) {
        // OFFER from Bob. Send this to alice.
        this.onIceCandidate(candidate, this.peerId)
      } else {
        devlog('service', 'rtc', 'All ICECandidates sent.')
      }
    }

    if (
      this.peerConnection.localDescription &&
      this.peerConnection.localDescription.sdp
    ) {
      devlog(
        'service',
        'rtc',
        'CREATING - Connection offer generated: ',
        this.peerConnection,
      )
      return this.peerConnection.localDescription.sdp
    }
  }

  // 2. Alice
  async joinConnection(offerSdp: string): Promise<string | undefined> {
    if (!this.peerConnection) return
    const acceptObject = createOfferFromSdp(offerSdp)
    const offerDesc = new RTCSessionDescription(acceptObject)
    await this.peerConnection.setRemoteDescription(offerDesc)
    await this.peerConnection.setLocalDescription(
      await this.peerConnection.createAnswer(),
    )

    this.peerConnection.ondatachannel = ({ channel }) => {
      // CONNECTED!
      this.channel = channel
      this.onConnected(this.peerId)
      // BOB sent a message to ALICE
      this.channel.onmessage = (event: MessageEvent) => {
        // You have the message!
        this._onMessage(event.data)
      }
    }

    this.peerConnection.onconnectionstatechange = () => {
      // if (!this.peerConnection) return
      this.onConnectionStateChanged(
        this.peerId,
        this.peerConnection.connectionState,
      )
    }

    // this.peerConnection.onicecandidate = ({ candidate }) => {
    //   if (candidate) {
    //     // OFFER from Bob. Send this to alice.
    //     this.onIceCandidate(candidate.toJSON(), this.peerId)
    //   } else {
    //     devlog('service', 'rtc', 'All ICECandidates sent.')
    //   }
    // }

    if (
      this.peerConnection.localDescription &&
      this.peerConnection.localDescription.sdp
    ) {
      devlog(
        'service',
        'rtc',
        'JOINING - Connection offer generated: ',
        this.peerConnection,
      )
      return this.peerConnection.localDescription.sdp
    }
  }

  // 3. Bob
  async acceptConnectionRequest(answerSdp: string, peerId: string) {
    if (!this.peerConnection) return
    const answerObject = createAnswerFromSdp(answerSdp)
    const answerDesc = new RTCSessionDescription(answerObject)
    await this.peerConnection.setRemoteDescription(answerDesc)
    this.peerId = peerId
    devlog('service', 'rtc', `Accepting connection to ${peerId}`)
  }

  // 4. Alice or Bob can now communicate
  sendText(text: string) {
    const unescaped = btoa(decodeURIComponent(encodeURIComponent(text)))
    this.sendJSON({ type: 'text', text: unescaped })
  }

  sendFiles(files: File[]) {
    for (let i = 0; i < files.length; i++) {
      this._filesQueue.push(files[i])
    }
    if (this._busy) return
    this._dequeueFile()
  }

  sendJSON(message: Record<any, any>) {
    this._send(JSON.stringify(message))
  }

  destroy() {
    this.peerConnection?.close()
    this.channel?.close()
    this.channel = undefined
    this.peerConnection = undefined
    this._chunker = undefined
    this._digester = undefined
    this._reader = undefined
  }

  // Internal

  _refresh() {
    console.warn('No RTC connection established')
    // TODO: implement refresh logic
  }

  _send(message: string | ArrayBuffer) {
    if (!this.channel) return this._refresh()
    this.channel?.send(message as string)
  }

  _dequeueFile() {
    if (!this._filesQueue.length) return
    this._busy = true
    const file = this._filesQueue.shift()
    if (!file) return
    this._sendFile(file)
  }

  _sendFile(file: File) {
    this.sendJSON({
      type: 'header',
      name: file.name,
      mime: file.type,
      size: file.size,
    })
    this._chunker = new FileChunker(
      file,
      (chunk) => {
        this.onUploadProgress(this._chunker!.progress, this.peerId)
        this._send(chunk)
      },
      (offset) => this._onPartitionEnd(offset),
    )
    this._chunker.nextPartition()
  }

  _onPartitionEnd(offset: number) {
    this.sendJSON({ type: 'partition', offset: offset })
  }

  _onReceivedPartitionEnd(offset: RTCMessage) {
    this.sendJSON({ type: 'partition-received', offset: offset })
  }

  _sendNextPartition() {
    if (!this._chunker || this._chunker.isFileEnd()) return
    this._chunker.nextPartition()
  }

  _sendProgress(progress: number) {
    this.sendJSON({ type: 'progress', progress: progress })
  }

  _onMessage(message: string | ArrayBuffer) {
    if (typeof message !== 'string') {
      this._onChunkReceived(message)
      return
    }
    const parsedMessage: RTCMessage = JSON.parse(message)
    switch (parsedMessage.type) {
      case RTC_MESSAGE_TYPES.HEADER:
        this._onFileHeader(parsedMessage)
        break
      case RTC_MESSAGE_TYPES.PART:
        this._onReceivedPartitionEnd(parsedMessage)
        break
      case RTC_MESSAGE_TYPES.PART_RECEIVED:
        this._sendNextPartition()
        break
      case RTC_MESSAGE_TYPES.PROGRESS:
        this._onDownloadProgress(parsedMessage.progress)
        break
      case RTC_MESSAGE_TYPES.TRANSFER_COMPLETE:
        this._onTransferCompleted()
        break
      case RTC_MESSAGE_TYPES.TEXT:
        this._onTextReceived(parsedMessage)
        break
    }
  }

  _onFileHeader(header: RTCMessage) {
    this._lastProgress = 0
    this._digester = new FileDigester(
      {
        name: header.name,
        mime: header.mime,
        size: header.size,
      },
      (file) => this._onFileReceived(file),
    )
  }

  _onChunkReceived(chunk: ArrayBuffer) {
    if (!chunk.byteLength || !this._digester) return

    this._digester.unChunk(chunk)
    const progress = this._digester.progress
    this._onDownloadProgress(progress)

    // occasionally notify sender about our progress
    if (progress - this._lastProgress < 0.01) return
    this._lastProgress = progress
    this._sendProgress(progress)
  }

  _onDownloadProgress(progress: number) {
    progress = progress * 100
    this.onDownloadProgress(progress, this.peerId)
  }

  _onFileReceived(proxyFile: RTCReceivedFile) {
    const { name, blob } = proxyFile // The blob is here
    this.sendJSON({ type: 'transfer-complete' })
    this.onFile(new File([blob], name), this.peerId)
  }

  _onTransferCompleted() {
    this._onDownloadProgress(1)
    this._reader = undefined
    this._busy = false
    this._dequeueFile()
  }

  _onTextReceived(message: RTCMessage) {
    const escaped = decodeURIComponent(escape(atob(message.text)))
    this.onText(escaped, this.peerId)
  }
}

class FileChunker {
  _chunkSize: number
  _maxPartitionSize: number
  _offset: number
  _partitionSize: number
  _file: File
  _onChunk: (buffer: ArrayBuffer) => void
  _onPartitionEnd: (number: number) => void
  _start: Date
  _reader: FileReader

  constructor(
    file: File,
    onChunk: (buffer: ArrayBuffer) => void,
    onPartitionEnd: (number: number) => void,
  ) {
    this._chunkSize = 64000 // 64 KB
    this._maxPartitionSize = 1e6 // 1 MB
    this._offset = 0
    this._partitionSize = 0
    this._file = file
    this._onChunk = onChunk
    this._onPartitionEnd = onPartitionEnd
    this._start = new Date()
    this._reader = new FileReader()
    this._reader.onload = (e: ProgressEvent<FileReader>) => {
      if (e.target && e.target.result) {
        this._onChunkRead(e.target.result as ArrayBuffer)
      }
    }
  }

  get progress() {
    return (this._offset / this._file.size) * 100
  }

  nextPartition() {
    this._partitionSize = 0
    this._readChunk()
  }

  _readChunk() {
    const chunk = this._file.slice(this._offset, this._offset + this._chunkSize)
    this._reader.readAsArrayBuffer(chunk)
    console.log(calcTransferRate(this._offset, this._start))
  }

  _onChunkRead(chunk: ArrayBuffer) {
    this._offset += chunk.byteLength
    this._partitionSize += chunk.byteLength
    this._onChunk(chunk)
    if (this.isFileEnd()) return
    if (this._isPartitionEnd()) {
      this._onPartitionEnd(this._offset)
      return
    }
    this._readChunk()
  }

  _isPartitionEnd() {
    return this._partitionSize >= this._maxPartitionSize
  }

  isFileEnd() {
    return this._offset >= this._file.size
  }
}

type FileDigesterCallback = (arg: {
  name: string
  mime: string
  size: number
  blob: Blob
}) => void

class FileDigester {
  progress: number
  _buffer: ArrayBuffer[]
  _bytesReceived: number
  _size: number
  _mime: string
  _name: string
  _start: Date
  _callback: FileDigesterCallback

  constructor(
    meta: { size: number; name: string; mime: string },
    callback: FileDigesterCallback,
  ) {
    this.progress = 0
    this._buffer = []
    this._bytesReceived = 0
    this._size = meta.size
    this._mime = meta.mime || 'application/octet-stream'
    this._name = meta.name
    this._start = new Date()
    this._callback = callback
  }

  unChunk(chunk: ArrayBuffer) {
    this._buffer.push(chunk)
    this._bytesReceived += chunk.byteLength
    this.progress = this._bytesReceived / this._size
    if (isNaN(this.progress)) this.progress = 1
    console.log(calcTransferRate(this._bytesReceived, this._start))

    if (this._bytesReceived < this._size) return
    // we are done
    const blob = new Blob(this._buffer, { type: this._mime })
    this._callback({
      name: this._name,
      mime: this._mime,
      size: this._size,
      blob: blob,
    })
  }
}

export default RTCConnection
