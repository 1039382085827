<template>
  <div
    class="paste-item"
    :class="{ 'cursor-pointer': clickToCopy }"
    tabindex="0"
    @click="onTapItem"
  >
    <div class="paste-item__container">
      <div ref="pasteSlot" class="paste-item__container-wrapper">
        <div class="paste-item__date">
          <p>{{ paste.device }} - {{ pasteDate }}</p>
        </div>
        <transition name="viewFade">
          <div
            v-if="
              paste.contentType === 'text/string' ||
              paste.contentType === 'text/plain'
            "
          >
            <PasteText
              v-if="decryptedPaste"
              :paste="decryptedPaste"
              :paste-id="pasteId"
            />
            <!--    TODO: Move placeholder out of this component    -->
            <PasteTextPlaceholder v-else />
          </div>
        </transition>
        <PasteImage
          v-if="decryptedPaste && paste.contentType.startsWith('image')"
          :paste="decryptedPaste"
          :upload-status="paste.uploadStatus"
          :download-status="paste.downloadStatus"
          :paste-id="pasteId"
          :on-image-rendered="onImageRendered"
        />
        <PasteFile
          v-if="decryptedPaste && !isImageOrText"
          :paste="decryptedPaste"
          :upload-status="paste.uploadStatus"
          :download-status="paste.downloadStatus"
          :paste-id="pasteId"
        />
      </div>
      <div v-show="!isMobile" class="paste-item__actions-container">
        <PasteActions
          v-if="showPasteActions"
          :paste="decryptedPaste"
          :paste-id="pasteId"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { computed, ref, onBeforeMount } from 'vue'
  import dayjs from 'dayjs'
  import isMobile from '../../utils/isMobile'
  import PasteActions from './PasteActions/PasteActions.vue'
  import { HTMLInputElement } from 'happy-dom'
  import copy from 'clipboard-copy'
  import { sealdService, touchService } from '/@/services'
  import pasteActionsService from '/@/services/pasteActionsService'
  import { useAppStateStore } from '/@/store/appState'
  import { storeToRefs } from 'pinia'
  import { Paste } from '/@/interfaces'

  const props = defineProps<{
    paste: Paste
    pasteId: string
  }>()

  // Dependencies

  const appState = useAppStateStore()
  const { clickToCopy } = storeToRefs(appState)

  // Data

  const pasteSlot = ref<null | HTMLInputElement>(null)
  const imageRendered = ref(false)
  const decryptedPaste = ref()

  // Computed

  const isImage = computed(() => {
    return props.paste.contentType.match('image/')
  })

  const isImageOrText = computed(() => {
    return (
      props.paste.contentType.match('image/') ||
      props.paste.contentType.match('text/string') ||
      props.paste.contentType.match('text/html')
    )
  })

  const pasteDate = computed(() => {
    return dayjs(props.paste.createdAt).format('D MMMM, YYYY, hh:mm:ss A')
  })

  const showPasteActions = computed(() => {
    if (isImage.value) {
      return (
        !isMobile.value &&
        !props.paste.uploadStatus?.isUploading &&
        decryptedPaste.value &&
        imageRendered.value
      )
    } else {
      return (
        !isMobile.value &&
        !props.paste.uploadStatus?.isUploading &&
        decryptedPaste.value
      )
    }
  })

  // Methods

  const onTapItem = async () => {
    if (isImage.value && !imageRendered.value) {
      return
    }
    if (isMobile.value) {
      if (touchService.sidebarIsOpen) return
      appState.currentMobileActionsPaste = {
        ...decryptedPaste.value,
        id: props.pasteId,
      }
      appState.showMobilePasteActions = true
    }
    if (appState.clickToCopy) {
      if (isImage.value) {
        await pasteActionsService.copyImage(props.paste)
      } else {
        await copy(decryptedPaste.value.content)
      }
    }
  }

  const onImageRendered = () => {
    imageRendered.value = true
  }

  // Hooks

  onBeforeMount(async () => {
    // Decrypt Paste
    await sealdService.waitForSession()
    const decryptedContent = await sealdService.decryptMessage(
      props.paste.content,
    )
    decryptedPaste.value = { ...props.paste, content: decryptedContent }
  })
</script>

<style lang="scss" scoped>
  .paste-item {
    position: relative;
    padding: $paste-item-padding-desktop;

    &__container {
      display: flex;
      align-items: center;

      .paste-item__actions-container {
        opacity: 0;
        margin: 0 auto;
      }

      .paste-item__container-wrapper {
        padding: 0 44px 11px 44px;
        min-height: 48px;
        width: -moz-fit-content;
        max-width: 80%;
        font-size: 16px;

        a {
          color: #71efde;
          line-break: anywhere;
        }
      }
    }

    &__date {
      color: #959595;
      transition: opacity 130ms ease-in-out;
      cursor: default;
      margin-bottom: 4px;
    }

    @media (max-width: $mobile) {
      .paste-item {
        padding: $paste-item-padding-mobile;

        &__container {
          width: 100%;

          .paste-item__actions-container {
            margin-left: auto;
          }

          .paste-item__container-wrapper {
            width: 100%;
            max-width: 100% !important;
            padding: 11px 28px !important;

            .paste-item-container {
              max-width: 100%;

              p {
                width: 100%;
              }
            }
          }
        }
      }
    }

    &:hover {
      background-color: #2f2f2f;

      .paste-item__actions-container {
        opacity: 1;
      }
    }
  }
</style>
