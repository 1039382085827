import { PastaDevTools } from '/@/utils/debug/addDebugUtilsToConsole'

declare global {
  interface Window {
    pastaDevTools: PastaDevTools
  }
}

export interface Paste {
  id: string
  content: string
  image?: Blob | File | null
  contentType: string
  deviceId: string
  device: string
  // roomId: string
  userId: string
  rtdbId?: string
  createdAt: string
  uploadStatus?: PasteUploadStatus
  downloadStatus?: PasteDownloadStatus
  filename?: any // this is here for sharing purposes
}

export interface Device {
  id?: string
  title: string
  type: string
  fcmId: string | null
  shouldLogOut?: boolean
  connected?: boolean
  pastes?: Paste[]
  userId: string
  lastOnline?: string
  settings: DeviceSettings
  createdAt: string
  updatedAt: string
}

export interface DeviceSettings {
  autoClipboardRead?: boolean
  autoFileDownload?: boolean
  autoSend?: boolean // unused
  updatedAt?: string
  color: string
}

export interface PasteUploadStatus {
  isUploading: boolean
  encrypting: boolean
  cryptAmount?: number
  uploadAmount: number
  uploadTask: unknown
}

export interface PasteDownloadStatus {
  decrypting: boolean
  isDownloading: boolean
  downloadAmount: string
  cryptAmount?: number
}

export interface PastaNotificationActions {
  buttonText: string
  onClick: (error?: unknown) => void | Promise<void>
}

export interface PastaNotification {
  message: string
  actions: PastaNotificationActions[]
}

export interface LocalSettings {
  [key: string]: boolean
}

export interface UploadTask {
  cancel: () => void
  catch: () => void
  pause: () => void
  resume: () => void
}

export interface ShareResponse {
  message: string
  shortened: string
  slug: string
}

// export interface Room {
//   id: string
//   name: string
//   pastes: Paste[]
// }
//
// export interface Rooms {
//   [key: string]: Room
// }

export interface QRLoginData {
  qrData: string
  encryptionInfo: {
    sessionId: string
    symEncKeyId: string
  }
}

export enum URLUserActionsParams {
  mode = 'mode',
  oobCode = 'oobCode',
  verifyEmail = 'verifyEmail',
  resetPassword = 'resetPassword',
}

export enum QRLoginStrings {
  KeyPathSeparator = '~',
  LogMeInPrefix = 'PLMI',
}

export interface WebRTCConnectionRequest {
  sdp: string
  requestingDeviceId: string
  time: string
}

export interface WebRTCConnectionAccept {
  sdp: string
  requestingDeviceId: string
  time: string
}

export type Enumify<T> = T[keyof T]
