<template>
  <button
    ref="button"
    class="button"
    :class="[type, disabled ? 'disabled' : '']"
    @click.stop="onClick"
  >
    <span
      v-show="!showSuccessIcon && !showLoadingSpinner"
      class="button__icon-container"
    >
      <slot name="icon" class="button__icon"></slot>
    </span>
    <LoadingSpinner
      v-if="isLoading || showLoadingSpinner"
      :color="'#fff'"
      :size="16"
    />
    <i-feather-check
      v-show="showSuccessIcon"
      class="button__checkmark"
      alt="Success"
    />
    <span v-show="!showConfirm" class="button__text">
      {{ text }}
    </span>
    <span v-show="showConfirm" class="button__text"> Are you sure? </span>
    <slot name="text"></slot>
  </button>
</template>

<script>
  import { defineComponent, reactive, toRefs, onMounted } from 'vue'
  import delay from '../../utils/delay'

  export default defineComponent({
    name: 'PsButton',
    props: {
      isLoading: {
        type: Boolean,
        required: false,
        default: false,
      },
      text: {
        type: String,
        required: false,
        default: '',
      },
      type: {
        type: String,
        required: false,
        default: 'small primary',
      },
      alt: {
        type: String,
        required: false,
        default: '',
      },
      callback: {
        type: Function,
        required: true,
      },
      confirmClick: {
        type: Boolean,
        required: false,
      },
      disabled: {
        type: Boolean,
        required: false,
      },
      showSuccessTick: {
        type: Boolean,
        required: false,
        default: false,
      },
    },
    setup(props) {
      const data = reactive({
        button: null,
        initialWidth: null,
        showSuccessIcon: false,
        showLoadingSpinner: false,
        showConfirm: false,
      })

      const methods = {
        async onClick() {
          // Prevent calling twice while callback running
          if (data.showLoadingSpinner || data.showSuccessIcon) return
          // Logic for double click to confirm
          if (props.confirmClick) {
            if (!data.showConfirm) {
              data.showConfirm = true
              return
            }
          }
          data.showLoadingSpinner = true
          await props.callback()
          data.showLoadingSpinner = false
          if (!props.showSuccessTick) return
          data.showSuccessIcon = true
          await delay(1200)
          data.showSuccessIcon = false
        },
      }

      onMounted(() => {
        data.initialWidth = data.button.value.clientWidth
      })

      return {
        ...toRefs(data),
        ...methods,
      }
    },
  })
</script>

<style lang="scss" scoped>
  .button {
    color: $text-primary;
    outline: none;
    border: none;
    border-radius: 3px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: width 200ms ease-in-out;
    touch-action: manipulation;
    user-select: none;

    img {
      height: 20px;
      width: 20px;
      cursor: pointer;
    }

    .button__icon {
      cursor: pointer;
      margin-right: 6px !important;
    }

    .loading-spinner {
      margin-right: 5px;
      margin-top: -3px;
    }

    &__icon-container {
      display: flex;
      margin-right: 6px;
    }

    &__text {
      padding-bottom: 2px;
    }

    &:hover {
      background-color: $accent-hover;
    }

    &:active {
      background-color: $accent-active;
    }
  }

  .small {
    height: 32px;
    padding: 0 10px 0 10px;
    min-width: 90px;

    img {
      height: 18px;
      width: 18px;
    }
  }

  .medium {
    height: 38px;
  }

  .large {
    padding: 8px 12px;
    min-width: 72px;
  }

  .primary {
    background-color: $accent-primary;
  }

  .secondary {
    background-color: $background-paste;
  }

  .danger {
    background-color: #ac2a2a;

    &:hover {
      background-color: #9c1a1a;
    }
  }

  .disabled {
    cursor: not-allowed;
    pointer-events: none;
    filter: opacity(0.5) grayscale(1);
  }
</style>
