export const percentage = (
  partialValue: number,
  totalValue: number,
): number => {
  return Math.round((100 * partialValue) / totalValue)
}

export const percentageOf = (
  percentage: number,
  targetValue: number,
): number => {
  return (percentage / 100) * targetValue
}

export const calcTransferRate = (bytesReceived: number, startTime: Date) => {
  const endTime = new Date()
  const duration = (endTime.getTime() - startTime.getTime()) / 1000 //in ms

  const seconds = Math.round(duration)

  const transferRateBps = bytesReceived / seconds

  const transferSpeedMbps = (transferRateBps / (1000 * 1000)).toFixed(2)

  const transferSpeedKbps = (transferRateBps / 1000).toFixed(2)

  if (Number(transferSpeedMbps) >= 1) {
    return transferSpeedMbps + ' Mbps'
  } else {
    return transferSpeedKbps + ' Kbps'
  }
}
