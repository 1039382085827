import { set, update, del, get } from 'idb-keyval'
import { Paste } from '../interfaces'
import devlog from '../utils/log'
import { PastaLocalStorageKeys } from '../../../../types'
import { Devices } from '/@/store/deviceStore'

export class idbService {
  async storePastaData(data: Devices) {
    devlog('service', 'idbService', 'Storing Pasta Data, rooms, pastses', data)
    await set('pasta-data', data)
  }

  async getPastaData(): Promise<Devices | undefined> {
    devlog('service', 'idbService', 'Getting Pasta Data, rooms, pastses')
    return await get('pasta-data')
  }

  async storeImagePaste(paste: Paste) {
    devlog('service', 'idbService', 'Storing Image Paste', paste)
    await set(paste.id, paste)
  }

  async getImagePaste(pasteId: string): Promise<Paste | undefined> {
    devlog('service', 'idbService', 'Getting Image Paste', pasteId)
    return await get(pasteId)
  }

  async storeUserProfilePicture(file: File | Blob): Promise<void> {
    devlog('service', 'idbService', 'Setting User Profile Picture', file)
    await set(PastaLocalStorageKeys.PROFILE_PICTURE_FILE, file)
  }

  async getUserProfilePicture(): Promise<File | undefined> {
    devlog('service', 'idbService', 'Getting User Profile Picture')
    return await get(PastaLocalStorageKeys.PROFILE_PICTURE_FILE)
  }

  public async updatePasteWithImage(
    pasteId: string,
    image: Blob,
  ): Promise<void> {
    await update(pasteId, (paste) => {
      if (paste) paste.image = image
      return paste
    })
  }

  public async removePaste(pasteId: string): Promise<void> {
    await del(pasteId)
  }
}

export default new idbService()
