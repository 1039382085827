import devlog from '/@/utils/log'
import {
  databaseService,
  sealdServiceAnonymous,
  startupService,
  userService,
} from '/@/services'
import {
  getAuth,
  onAuthStateChanged,
  connectAuthEmulator,
  signInWithCustomToken,
  signInAnonymously,
} from 'firebase/auth'
import { useUserStore } from '/@/store/user'
import { useAppStateStore } from '/@/store/appState'
import { QRLoginData, QRLoginStrings } from '/@/interfaces'

class loginService {
  private loggingInViaQR = false
  private serializedId: string | null = null
  private qrDatabasePath: string | null = null

  public authorizeFirebase(): void {
    const auth = getAuth()
    if (import.meta.env.VITE_DEV_EMULATORS) {
      // Emulator
      connectAuthEmulator(auth, 'http://127.0.0.1:9099')
      // Emulator
    }
    console.log(databaseService)
    onAuthStateChanged(auth, async (user) => {
      const userStore = useUserStore()
      const appState = useAppStateStore()

      if (!user || !user.email) {
        if (appState.isLoggingOut) return
        appState.showAuthentication = true
        return
      }

      if (!user.emailVerified && !appState.showEmailVerification) {
        devlog('auth', 'FirebaseLogin', 'User not verified yet', user)
        appState.showAskForEmailVerification = true
        return
      }
      try {
        devlog('auth', 'FirebaseLogin', 'Logged in', user)
        userStore.loggedIn = true
        appState.showLogMeInQR = false
        userStore.userId = user.uid
        if (this.loggingInViaQR) {
          appState.show = true
          await startupService.onLoginSuccessViaQRCode(
            <string>this.serializedId,
            <string>this.qrDatabasePath,
          )
          appState.isLoggingInWithQR = false
          this.resetLocals()
        } else {
          await startupService.onLoginSuccess()
        }
        await userService.handleStoreUpdatedProfilePic(user.photoURL)
      } catch (error) {
        devlog('auth', 'FirebaseLogin - ERROR', 'Login Failed', error)
        appState.showAuthentication = true
      }
    })
  }

  public async onLoginQRScan(code: string): Promise<void> {
    if (!code || !code.includes(QRLoginStrings.KeyPathSeparator)) return

    if (code.startsWith(QRLoginStrings.LogMeInPrefix)) {
      await this.onScanLogMeInQR(code)
    } else {
      await this.logInWithQR(code)
    }
  }

  public async onScanLogMeInQR(code: string): Promise<void> {
    // Split string looks like "PLMI~path~key"
    const [_prefix, path, key] = code.split(QRLoginStrings.KeyPathSeparator)

    await databaseService.transmitLogMeInSession(path, key)
  }

  public async executeLogMeInQR(
    qrLoginData: QRLoginData,
    password: string,
    path: string,
  ): Promise<void> {
    if (!qrLoginData || !password) return
    this.loggingInViaQR = true
    this.qrDatabasePath = path
    const appState = useAppStateStore()
    appState.showAuthentication = false
    appState.isLoggingInWithQR = true

    try {
      const auth = getAuth()

      const jwt = await databaseService.getJWT()

      if (!jwt) return

      const { qrData, encryptionInfo } = qrLoginData

      await sealdServiceAnonymous.init()
      await sealdServiceAnonymous.setEncryptionSession(
        jwt,
        encryptionInfo.sessionId,
        encryptionInfo.symEncKeyId,
        password,
      )
      const decryptedSessionData =
        await sealdServiceAnonymous.decryptMessage(qrData)

      if (!decryptedSessionData) return

      const { t, s } = JSON.parse(decryptedSessionData)
      this.serializedId = s // Read in this.authorizeFirebase()

      await signInWithCustomToken(auth, t)
    } catch (error) {
      this.resetLocals()
      console.error(error)
    }
  }

  public async logInWithQR(code: string): Promise<void> {
    try {
      this.loggingInViaQR = true
      const auth = getAuth()

      const [path, password] = code.split(QRLoginStrings.KeyPathSeparator)
      this.qrDatabasePath = path
      const jwt = await databaseService.getJWT()
      const qrLoginDataResponse = await databaseService.getQrLoginData(path)

      if (!qrLoginDataResponse || !jwt) return

      const { qrData, encryptionInfo } = qrLoginDataResponse

      await sealdServiceAnonymous.init()
      await sealdServiceAnonymous.setEncryptionSession(
        jwt,
        encryptionInfo.sessionId,
        encryptionInfo.symEncKeyId,
        password,
      )
      const decryptedSessionData =
        await sealdServiceAnonymous.decryptMessage(qrData)

      if (!decryptedSessionData) return

      const { t, s } = JSON.parse(decryptedSessionData)
      this.serializedId = s

      await signInWithCustomToken(auth, t)
    } catch (error) {
      this.resetLocals()
      console.error(error)
    }
  }

  async signInAnonymously() {
    await signInAnonymously(getAuth())
  }

  resetLocals() {
    this.loggingInViaQR = false
    this.serializedId = null
    this.qrDatabasePath = null
  }
}

export default new loginService()
