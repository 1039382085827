import { downloadBlob } from '../utils/files/saveFile'
import { idbService, inAppNotificationService, ipcService } from './index'
import DownloadService from '/@/services/DownloadService'
import { Device, Paste } from '../interfaces'
import { writeImageToClipboard } from '../utils/clipboardUtils'
import { useShare } from '@vueuse/core'
import getFilename from '../utils/files/getFilename'
import getFileURL from '../utils/firebase/getFileURL'
import getNewestPaste from '/@/utils/getNewestPaste'
import { useAppStateStore } from '/@/store/appState'
import { usePasteListStore } from '/@/store/pasteList'
import { useDeviceStore } from '/@/store/deviceStore'

class pasteActionsService {
  public async handleIncomingPasteFromFirebase(data: Record<string, Device>) {
    const appState = useAppStateStore()
    const newestPaste = getNewestPaste(data)
    if (!newestPaste) return
    // await showNotification()
    inAppNotificationService.showRoomUnreadNotificationDot(newestPaste.deviceId)
    appState.newestPaste = newestPaste
    // Handle text auto write to clipboard
    if (newestPaste.contentType === 'text/string') {
      await ipcService.handleAutoCopyIncomingTextToClipboard(newestPaste)
    } else {
      // handle image/file auto write to clipboard
      if (appState.autoDownload || appState.clipboardAutoReceive) {
        const downloader = new DownloadService()
        await downloader.downloadFile(newestPaste as Paste, newestPaste.id)
        return
      }
    }
    // Show room notification icon
  }

  public async handleIncomingPasteFromRTC(data: string, deviceId: string) {
    const pasteListStore = usePasteListStore()
    const deviceStore = useDeviceStore()
    try {
      const paste = JSON.parse(data) as Paste
      if (!paste || !paste.content || !paste.device) {
        new Error('shits fucked m8')
        return
      }
      pasteListStore.addNewPasteToPasteList(paste, deviceId)
      await idbService.storePastaData(deviceStore.devices.value)
    } catch (e) {
      console.error(e)
    }
  }

  public async downloadPaste(paste: Paste, pasteId: string) {
    if (paste.contentType.startsWith('image')) {
      const storedImagePaste = await idbService.getImagePaste(paste.id)
      downloadBlob(storedImagePaste?.image as Blob, paste.content)
    } else {
      const downloader = new DownloadService()
      await downloader.downloadFile(paste as Paste, pasteId)
    }
  }

  public async copyImage(paste: Paste) {
    const pasteItem = await idbService.getImagePaste(paste.id)
    if (!pasteItem?.image) return
    if (pasteItem.image && pasteItem.image.type === 'image/png') {
      await writeImageToClipboard(pasteItem.image as Blob)
    } else {
      this.convertImageToPNGCopy(pasteItem.image)
    }
  }

  public async shareAPISharePaste(paste: Paste) {
    let file
    const { share, isSupported } = useShare()
    const filename = getFilename(paste.content)
    if (!isSupported) return
    if (paste.contentType.startsWith('image') && paste.image) {
      file = new File([paste.image as Blob], filename, {
        type: paste.contentType,
      })
      await share({
        title: filename,
        text: 'Shared image from Pasta.to',
        url: location.href,
        files: [file],
      })
    } else if (paste.contentType.startsWith('text')) {
      await share({
        title: paste.content,
        text: paste.content,
        url: location.href,
      })
    } else if (!paste.contentType.startsWith('text')) {
      const content = await getFileURL(paste.content)
      await share({
        title: '',
        text: '',
        url: content,
      })
    }
  }

  public async shareAPIText(text: string) {
    const { share, isSupported } = useShare()
    if (!isSupported) return
    await share({
      title: '',
      text: '',
      url: text,
    })
  }

  public convertImageToPNGCopy(blob: Blob) {
    const img = new Image()
    const c = document.createElement('canvas')
    const ctx = c.getContext('2d')

    function setCanvasImage(
      blob: Blob,
      func: (imgBlob: Blob) => Promise<void>,
    ) {
      img.onload = function () {
        const { naturalWidth, naturalHeight } = <HTMLImageElement>this
        c.width = naturalWidth
        c.height = naturalHeight
        ctx?.drawImage(<HTMLImageElement>this, 0, 0)
        c.toBlob(async (blob) => {
          await func(<Blob>blob)
        }, 'image/png')
      }
      img.src = URL.createObjectURL(blob)
    }

    setCanvasImage(blob, async (imgBlob) => {
      await writeImageToClipboard(imgBlob)
    })
  }
}

export default new pasteActionsService()
