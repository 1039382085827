<template>
  <div v-if="device">
    <div class="device" :style="[settingsIsOpen ? deviceBorder : '']">
      <div
        class="device__container"
        :class="{ 'device__container--active': isActiveDevice }"
        @mousedown="openDevice(device.id!)"
      >
        <div class="device__color-text">
          <div class="device-color" :style="deviceColor">
            <div
              v-show="showNotification"
              class="device-color--notification"
            ></div>
          </div>
          <p class="truncate">{{ device.title }}</p>
        </div>
        <LiveIndicatorIcon
          class="live-indicator"
          v-if="!isAll"
          :is-connected="isDeviceConnected"
          :is-offline="!isDeviceConnected"
        />
        <!--        <span v-show="isActiveDevice" class="device-options">-->
        <!--          <i-feather-tool-->
        <!--            v-show="!settingsIsOpen"-->
        <!--            class="device-options__info"-->
        <!--            alt="Settings"-->
        <!--            @mousedown="openSettings()"-->
        <!--          />-->
        <!--        </span>-->
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { computed, ref, toRefs } from 'vue'
  import { initAutoRead } from '/@/utils/AutoRead'
  import { getHashColor } from '/@/utils/getRandomColor'
  import { RTCService, touchService } from '../../services'
  import delay from '../../utils/delay'
  import isMobile from '../../utils/isMobile'
  import { hideFaviconNotification } from '/@/utils/toggleFaviconNotification'
  import { useDeviceStore } from '/@/store/deviceStore'
  import { useAppStateStore } from '/@/store/appState'
  import LiveIndicatorIcon from '/@/components/Navbar/LiveIndicatorIcon.vue'
  import { Device } from '/@/interfaces'

  // Props

  const props = defineProps<{
    isAll?: boolean
    device: Device
  }>()

  // Dependencies

  const deviceStore = useDeviceStore()
  const appState = useAppStateStore()
  const { thisDeviceId, selectedDeviceId, devicesWithUnseenPastes } =
    toRefs(deviceStore)

  // Data

  const deviceBorder = {
    borderBottom: 'rgba(255, 255, 255, 0.12)',
  }

  const settingsIsOpen = ref(false)

  // Methods

  const openSettings = () => {
    deviceStore.showDeviceSettings = true
  }

  const openDevice = async (deviceId: string) => {
    if (deviceId === thisDeviceId.value) return
    deviceStore.setActiveDevice(deviceId)
    deviceStore.removeDeviceFromUnseenPaste(deviceId)
    hideFaviconNotification()
    if (!isMobile.value) return
    await delay(150)
    touchService.slideElement(0, appState.mainElement)
    initAutoRead()
  }

  // Computed

  const isActiveDevice = computed(
    () => props.device.id === selectedDeviceId.value,
  )

  const isDeviceConnected = computed(() => {
    return deviceStore.connectedDevices[props.device.id!] || false
    // props.device.connected
  })

  // const showNotificationIcon = computed(() => props.deviceId)

  const showNotification = computed(() =>
    devicesWithUnseenPastes.value?.includes(props.device.id!),
  )

  const deviceColor = computed(() => {
    return {
      backgroundColor:
        props.device.settings?.color || getHashColor(props.device.id!),
    }
  })
</script>

<style lang="scss" scoped>
  .device {
    display: flex;
    align-items: center;
    min-height: min-content;
    width: 100%;
    overflow: hidden;
    margin: 10px 0;
    cursor: pointer;
    touch-action: manipulation;

    p {
      font-size: 16px;
      font-weight: bold;
      padding-right: 5px;
      max-width: 83%;
    }

    &:hover {
      color: $text-secondary;
    }

    .device-color {
      min-height: 24px;
      min-width: 24px;
      border-radius: 100px;
      margin-right: 8px;
      transition: background-color 250ms ease-in-out;

      .device-color--notification {
        height: 8px;
        width: 8px;
        background-color: $alert;
        border-radius: 50px;
        margin-left: auto;
      }
    }

    &__color-text {
      display: flex;
      width: calc(100% - 24px);
      align-items: center;
    }

    &__container {
      display: flex;
      width: 100%;
      padding: 10px 10px;
      border-radius: 50px;
      align-items: center;
      transition: background-color 100ms ease-in-out;

      .live-indicator {
        transform: scale(0.8);
        margin-left: 4px;
      }

      &--active {
        background-color: $background-paste;
      }
    }

    .generic-button {
      margin: 0 8px 0 auto;
    }

    span.device-options {
      display: grid;
      place-content: center;
      transition: width 100ms ease-in-out;

      .pasta-icon {
        cursor: pointer;

        &:hover {
          filter: brightness(0.8);
        }
      }
    }
  }
</style>
