<template>
  <transition name="viewFade">
    <PastePreview v-show="showPastePreview" />
  </transition>
  <MobilePasteActions v-if="showMobilePasteActions" />
  <Sidebar
    v-show="!isMobile || showSidebar"
    @touchstart="handleTouchStart"
    @touchmove="handleTouchDrag"
    @touchend="handleTouchEnd"
  />
  <Overlays />
  <main
    ref="mainElement"
    @dragover="handleDrag"
    @touchstart="handleTouchStart"
    @touchmove="handleTouchDrag"
    @touchend="handleTouchEnd"
    @click="closeSidebar"
  >
    <Navbar />
    <div class="paste-list-area" :class="{ 'no-interact': sidebarIsOpen }">
      <PasteList />
    </div>
    <PasteInput />
  </main>
  <MaintenanceMode v-if="showMaintenanceMode" />
</template>

<script setup lang="ts">
  import { onMounted, ref, computed, onBeforeUnmount } from 'vue'
  import './css/main.scss'
  import { handlePaste } from './utils/clipboardUtils'
  import { RTCService, startupService, touchService } from './services'
  import isMobile from './utils/isMobile'
  import devlog from './utils/log'
  import {
    MobilePasteActions,
    MaintenanceMode,
  } from './components/asyncComponents'
  import { useAppStateStore } from '/@/store/appState'
  import { storeToRefs } from 'pinia'
  import isDev from '/@/utils/isDev'
  import isStaging from '/@/utils/isStaging'
  import { useUserStore } from '/@/store/user'

  // Dependencies

  const userStore = useUserStore()
  const appState = useAppStateStore()
  const { loggedIn } = storeToRefs(userStore)
  const { showMaintenanceMode, showMobilePasteActions, showPastePreview } =
    storeToRefs(appState)

  // Data

  const mainElement = ref(null)
  const showFileDrop = ref(false)
  const showSidebar = ref(!isMobile)
  const inited = ref(false)
  // !BETA
  const showBetaForm = ref(
    !(localStorage.getItem('pasta:betaFormSubmitted') === 'true') || !loggedIn,
  )

  // Computed

  const sidebarIsOpen = computed(() => touchService.sidebarIsOpen)

  // Methods

  const handleTouchStart = (e: TouchEvent) => {
    touchService.handleTouchStart(e, 'main')
    showSidebar.value = true
  }

  const handleTouchDrag = (e: TouchEvent) => {
    touchService.handleTouchDrag(e)
  }

  const handleTouchEnd = () => {
    touchService.handleTouchEnd('main')
  }

  const handleDrag = (e: DragEvent) => {
    e.preventDefault()
    if (appState.isOfflineMode) return
    showFileDrop.value = true
  }

  const closeSidebar = async () => {
    if (!isMobile || !touchService.sidebarIsOpen) return
    await touchService.closeMenuOnTop()
  }

  // Hooks

  onMounted(async () => {
    // !BETA
    showBetaForm.value = !loggedIn.value && !import.meta.env.DEV
    if (isDev || isStaging) {
      const debugTools = await import('/@/utils/debug/addDebugUtilsToConsole')
      debugTools.addDebugUtilsToConsole()
    }
    appState.mainElement = mainElement.value
    appState.loadingOverlayProgress = 100
    if (!inited.value) {
      await startupService.init()
    }
    inited.value = true
  })

  onBeforeUnmount(() => {
    devlog('app', 'Unmount', 'Unmounting...')
    RTCService.disconnectAll()
    window.removeEventListener('paste', async (event) => {
      await handlePaste(event)
    })
  })
</script>

<style lang="scss">
  #app {
    width: 100%;
    height: 100%;
    display: flex;
    color: #ececec;
    overflow: hidden;
    background-color: $background-primary;

    main {
      position: relative;
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;
      background-color: $background-primary;
      z-index: 2;
      pointer-events: all;

      .paste-list-area {
        overflow-x: hidden;
        height: calc(100% - 61px);
      }
    }

    .sidebar-navbar-wrapper {
      display: flex;
    }

    @media (max-width: $mobile) {
      width: 100vw !important;
    }
  }
</style>
