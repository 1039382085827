<template>
  <div class="paste-details">
    <div
      ref="PasteUploadBar"
      class="paste-details--container"
      :class="{
        'show-progress-bar': showUploadProgress,
        'show-actions': showUploadProgress,
        'hide-actions': !showActions,
      }"
    >
      <ProgressBar v-if="showUploadProgress" :progress="uploadProgress" />
      <div v-if="true" class="paste-file__uploading">
        <UploadSpinner
          class="paste-upload-spinner__img"
          :upload-progress="uploadProgress"
          :size="16"
          :success-icon-delay="1800"
        />
        <UploadControls
          v-if="showUploadProgress && !isEncrypting"
          class="upload-controls__img"
          :upload-task="uploadTask"
          :paste-id="pasteId"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { ref, watch } from 'vue'

  const props = withDefaults(
    defineProps<{
      pasteId: string
      uploadTask: any
      uploadProgress?: number
      showUploadProgress: boolean
      isEncrypting: boolean
    }>(),
    {
      uploadProgress: 0,
      showUploadProgress: true,
      isEncrypting: false,
    },
  )

  const showActions = ref(false)

  watch(
    () => props.uploadProgress,
    async (progress) => {
      // if (progress === 100) {
      //   await delay(1200)
      //   showActions.value = false
      // }
      if (progress && progress !== 100 && !showActions.value) {
        showActions.value = true
      }
    },
  )
</script>

<style scoped lang="scss">
  .paste-details {
    width: 100%;
    height: 100%;
    position: absolute;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    overflow: hidden;
    border-bottom-right-radius: 14px;
    z-index: 1;

    .paste-details--container {
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding-left: 14px;
      padding-right: 20px;
      transition: opacity 200ms ease-in-out;
      background: $background-input;
      border-radius: 14px 0 14px 14px;
    }

    .paste-file__uploading {
      min-width: 90px;
      gap: 4px;
    }

    .show-progress-bar {
      width: 100% !important;
    }

    .show-actions {
      opacity: 1;
    }

    .hide-actions {
      opacity: 0;
    }
  }
</style>
