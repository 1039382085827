import {
  getStorage,
  ref,
  uploadBytesResumable,
  connectStorageEmulator,
} from 'firebase/storage'
import { getDeviceName } from '../devices/getDeviceName'
import devlog from '../log'
import { databaseService, idbService, sealdService } from '/@/services'
import nanoId from '../generateNanoId'
import { usePasteListStore } from '/@/store/pasteList'
import { useUserStore } from '/@/store/user'
import { getApp } from 'firebase/app'
import { useDeviceStore } from '/@/store/deviceStore'

const firebaseUploader = async (file: File, type: string): Promise<void> => {
  const deviceStore = useDeviceStore()
  const userStore = useUserStore()
  // const appState = useAppStateStore()
  const pasteListStore = usePasteListStore()
  devlog('app', 'Firebase Upload', 'Uploading File...', file)
  let fileName

  if (file.name === 'image.png' || !file.name) {
    fileName = 'image-' + nanoId()
  } else {
    fileName = file.name
  }

  if (!type) type = 'misc/unknown'

  const randomId = nanoId()
  const fileReferenceName = `${userStore.userId}/${deviceStore.thisDeviceId}/${randomId}/${fileName}`

  const pasteItem = {
    id: randomId,
    content: (await sealdService.encryptMessage(fileReferenceName)) ?? '',
    image: type.startsWith('image') ? file : null,
    contentType: type,
    device: getDeviceName(),
    deviceId: deviceStore.thisDeviceId,
    userId: userStore.userId,
    createdAt: new Date().toISOString(),
    uploadStatus: {
      isUploading: false,
      encrypting: true,
      cryptAmount: 0,
      uploadAmount: 0,
      uploadTask: null,
    },
  }

  // We store the image in IDB so that PasteImage can render the image locally
  if (pasteItem.image) {
    await idbService.storeImagePaste(pasteItem)
  }

  pasteListStore.uploadingPastes.push(pasteItem)

  const encryptedFile = await sealdService.encryptFile(
    file,
    file.name || 'image.png',
    randomId,
  )

  const app = getApp()
  const storage = getStorage(app)
  if (import.meta.env.VITE_DEV_EMULATORS) {
    // Emulator
    connectStorageEmulator(storage, '127.0.0.1', 9199)
    // Emulator
  }
  const storageRef = ref(storage, fileReferenceName)
  const uploadTask = uploadBytesResumable(storageRef, encryptedFile as File)

  pasteListStore.updateUploadingPasteStatus(randomId, {
    isUploading: true,
    encrypting: false,
    cryptAmount: 0,
    uploadAmount: 0,
    uploadTask: uploadTask,
  })

  uploadTask.on(
    'state_changed',
    (snapshot) => {
      const progress = (
        (snapshot.bytesTransferred / snapshot.totalBytes) *
        100
      ).toFixed(2)

      pasteListStore.updateUploadingPasteStatus(randomId, {
        isUploading: true,
        encrypting: false,
        uploadAmount: ~~progress,
        uploadTask: uploadTask,
      })
    },
    (error: unknown) => {
      devlog('error', 'Firebase Upload', 'Upload failed', error)
    },
    () => {
      devlog('app', 'Firebase Upload', 'File Uploaded')
      databaseService.createPaste(fileReferenceName, type, { id: randomId })
      pasteListStore.removeUploadingPaste(randomId)
    },
  )
}

export default firebaseUploader
