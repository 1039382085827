<template>
  <LiveIndicatorIcon
    :tooltip-text="tooltipText"
    :is-connected="isConnected"
    :is-offline="isOfflineMode"
  />
</template>

<script setup lang="ts">
  import { computed } from 'vue'
  import { useAppStateStore } from '/@/store/appState'
  import { storeToRefs } from 'pinia'
  import LiveIndicatorIcon from '/@/components/Navbar/LiveIndicatorIcon.vue'

  // Dependencies

  const appState = useAppStateStore()
  const { isConnected, isOfflineMode } = storeToRefs(appState)

  // Computed

  const tooltipText = computed(() => {
    if (isOfflineMode.value) {
      return 'Offline mode'
    }
    if (!isConnected.value && !isOfflineMode.value) {
      return 'Not connected'
    }
    return 'Connected'
  })
</script>

<style lang="scss">
  .live-indicator {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .ring {
      position: absolute;
      border: 1px solid #62bd19;
      border-radius: 30px;
      height: 25px;
      width: 25px;
      animation: pulsate 3s ease-out;
      animation-iteration-count: infinite;
      opacity: 0;
    }

    .circle {
      margin: auto;
      width: 15px;
      height: 15px;
      background-color: $success;
      border-radius: 50%;
      transition: background-color 250ms ease-in-out;
    }

    .ring-off {
      animation: none !important;
    }

    .circle-off {
      background-color: #ffc107 !important;
    }

    .circle-offline {
      background-color: $alert !important;
    }
  }

  @keyframes pulsate {
    0% {
      transform: scale(0.1, 0.1);
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      transform: scale(1.2, 1.2);
      opacity: 0;
    }
  }
</style>
