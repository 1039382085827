import isDev from '/@/utils/isDev'

type LogZone =
  | 'init'
  | 'service'
  | 'error'
  | 'fetch'
  | 'app'
  | 'pwa'
  | 'websocket'
  | 'auth'
  | 'rtc'
  | 'electron'

type LogType = 'info' | 'warn' | 'error'

const devlog = (
  zone: LogZone,
  area: string,
  message: string,
  extra: unknown | string = '',
  logType: LogType = 'info',
): void => {
  const devLogging = isDev
    ? true
    : localStorage.getItem('pasta:devLogging') === 'true'

  if (!devLogging) return

  let color

  let typeText

  const dividerStyle = 'background: unset; color: white; font-weight: bold'

  const backgroundColor = (color: string) =>
    `background-color: ${color}; color: white; font-weight: bold`

  const doLog = (type: string, color: string) => {
    console[logType](
      `%c${type}%c - %c${area}%c -`,
      backgroundColor(color),
      dividerStyle,
      backgroundColor('unset'),
      dividerStyle,
      message,
      extra,
    )
  }

  switch (zone) {
    case 'init':
      typeText = 'Init'
      color = 'green'
      doLog(typeText, color)
      break
    case 'service':
      typeText = 'Service'
      color = '#a341c2'
      doLog(zone, color)
      break
    case 'error':
      typeText = 'ERROR'
      color = '#bf0000'
      doLog(zone, color)
      break
    case 'fetch':
      typeText = 'Fetch'
      color = '#bd8400'
      doLog(zone, color)
      break
    case 'app':
      typeText = 'App'
      color = '#3fb983'
      doLog(zone, color)
      break
    case 'pwa':
      typeText = 'PWA'
      color = '#1a9db4'
      doLog(zone, color)
      break
    case 'websocket':
      typeText = 'Websocket'
      color = '#811ab4'
      doLog(zone, color)
      break
    case 'auth':
      typeText = 'Auth'
      color = '#839b15'
      doLog(zone, color)
      break
    case 'electron':
      typeText = 'Electron'
      color = '#9febf9'
      doLog(zone, color)
      break
    case 'rtc':
      typeText = 'webRTC'
      color = '#008802'
      doLog(typeText, color)
      break
  }
}

export default devlog
