import { PastaNotificationActions } from '../interfaces'
import delay from '../utils/delay'
import devlog from '../utils/log'
import platform from '/@/utils/devices/platform'
import { showFaviconNotification } from '/@/utils/toggleFaviconNotification'
import { useDeviceStore } from '/@/store/deviceStore'
import { useAppStateStore } from '/@/store/appState'

// IN APP NOTIFICATIONS

const firefoxClipboardPermission = async () => {
  // Firefox get permissions hack
  let permissionStatus: any
  // TODO: Deal with this not working on safari, does not prompt for permission
  if (
    platform.browser.name === 'Firefox' ||
    platform.browser.name === 'Safari' ||
    platform.browser.name === 'SafariMobile'
  ) {
    permissionStatus = { state: 'prompt' }
  } else {
    const queryOpts = { name: 'clipboard-read' as PermissionName }
    permissionStatus = await navigator.permissions.query(queryOpts)
    devlog(
      'service',
      'Notifications',
      'Getting clipboard read access: ',
      permissionStatus,
    )
  }
  return permissionStatus
}

class inAppNotificationService {
  public async showInAppNotification(
    message: string,
    actions: PastaNotificationActions[],
    showTime: number | null = null,
  ) {
    const appState = useAppStateStore()
    appState.notification = {
      message,
      actions,
    }
    if (showTime) {
      await delay(showTime)
      this.hideInAppNotification()
    }
  }

  public showRoomUnreadNotificationDot(roomId: string) {
    const appState = useAppStateStore()
    if (!appState.windowFocused) {
      const deviceStore = useDeviceStore()
      showFaviconNotification()
      deviceStore.addDeviceToUnseenPastes(roomId)
    }
  }

  public hideInAppNotification() {
    const appState = useAppStateStore()
    appState.notification = null
  }

  // public async enableNotifications() {
  //   // TODO: Allow mobile/background notifications.
  // }
  //
  // public disableNotifications() {
  //   const appState = useAppStateStore()
  //   appState.showNotifications = true
  // }
  //
  // public async requestNotificationPermission() {
  //   const appState = useAppStateStore()
  //   if (appState.showNotifications && Notification.permission !== 'default')
  //     return
  //   const showBrowserNotificationPermissionDialogue = async () => {
  //     if (window.Notification && Notification.permission !== 'denied') {
  //       await Notification.requestPermission()
  //
  //       this.hideInAppNotification()
  //
  //       const notificationPermissionStatus =
  //         Notification.permission == 'granted'
  //
  //       if (notificationPermissionStatus) {
  //         await this.enableNotifications()
  //       } else {
  //         this.disableNotifications()
  //       }
  //     }
  //   }
  //   const actions = [
  //     {
  //       buttonText: 'yes',
  //       onClick: showBrowserNotificationPermissionDialogue,
  //     },
  //     {
  //       buttonText: 'no',
  //       onClick: this.hideInAppNotification,
  //     },
  //   ]
  //   await this.showInAppNotification('Enable Notifications?', actions)
  // }

  public async requestClipboardPermission() {
    const appState = useAppStateStore()
    const permissionStatus = await firefoxClipboardPermission()
    // If permission is already granted, don't show the notification at all.
    if (permissionStatus.state === 'granted') return

    if (permissionStatus.state === 'denied' || !appState.allowClipboardAccess) {
      return
    }

    const denyBrowserClipboardPermission = async (error: unknown) => {
      const appState = useAppStateStore()
      appState.allowClipboardAccess = false
      devlog(
        'service',
        'Notifications - ERROR',
        'Clipboard access permission denied',
        error,
      )
      this.hideInAppNotification()
      // TODO: UPDATE USER
    }

    const showBrowserClipboardPermissionDialogue = async () => {
      const appState = useAppStateStore()
      if (navigator.clipboard && permissionStatus.state === 'prompt') {
        try {
          await navigator.clipboard.readText()
          appState.hasClipboardWriteAccess = true
          appState.allowClipboardAccess = true
          devlog(
            'service',
            'Notifications',
            'Clipboard access permission granted!',
          )
          // TODO: UPDATE USER
        } catch (error) {
          await denyBrowserClipboardPermission(error)
        }
      }
      this.hideInAppNotification()
    }
    const actions = [
      {
        buttonText: 'yes',
        onClick: await showBrowserClipboardPermissionDialogue,
      },
      {
        buttonText: 'no',
        onClick: await denyBrowserClipboardPermission,
      },
    ]
    await this.showInAppNotification(
      'Enable Clipboard Sync? (Recommended)',
      actions,
    )
  }

  public async notifyDeniedClipboardAccess() {
    const permissionStatus = await firefoxClipboardPermission()

    // If permission is already granted, don't show the notification at all.
    if (
      permissionStatus.state === 'granted' ||
      permissionStatus.state === 'prompt'
    )
      return

    const actions = [
      {
        buttonText: 'Ok',
        onClick: this.hideInAppNotification,
      },
    ]
    await this.showInAppNotification(
      'Clipboard access is denied. Please re-enable it in the site settings' +
        ' for this page.',
      actions,
    )
  }
}

export default new inAppNotificationService()
