import { getToken, onMessage, getMessaging } from 'firebase/messaging'
import { type Unsubscribe } from 'firebase/messaging'
import firebaseConfig from '../config/firebaseConfig'
import devlog from '../utils/log'
import { getApp } from 'firebase/app'
import { useDeviceStore } from '/@/store/deviceStore'

class fcmService {
  // messagingInstance is the unsubscribe function returned by onMessage
  private messagingInstance: Unsubscribe | null = null

  public initFCMServiceWorker() {
    const app = getApp()
    if (!app) return
    const messaging = getMessaging(app)
    this.messagingInstance = onMessage(messaging, (payload) => {
      devlog('pwa', 'FCMServiceWorker', 'Received Message!', payload)
    })
  }

  // Foreground FCM
  public initForegroundFCM() {
    const deviceStore = useDeviceStore()
    if (!deviceStore.thisFcmId) {
      devlog('service', 'FCM', 'No FCM Device Present')
      return
    }
    devlog('service', 'FCM', 'Setting up receiving foreground FCM messages')
    const app = getApp()
    const messaging = getMessaging(app)
    this.messagingInstance = onMessage(messaging, async (payload) => {
      await this.handleIncomingFCM(payload)
    })
  }

  public async handleIncomingFCM(payload: any) {
    devlog('service', 'FCM', 'Incoming FCM message', payload)
  }

  public async getFCMToken(): Promise<string | null> {
    if (window.isDesktop) return null
    const app = getApp()
    const messaging = getMessaging(app)
    return getToken(messaging, { vapidKey: firebaseConfig.vapidKey })
      .then(async (currentToken) => {
        if (currentToken) {
          devlog('service', 'FCM', 'Retrieved FCM token, ' + currentToken)
          return currentToken
        } else {
          devlog('service', 'FCM', 'Error getting FCM token')
          return null
        }
      })
      .catch((err) => {
        devlog('error', 'FCM', 'Error getting FCM token ' + err)
        return null
      })
  }

  public unsubscribeFromMessaging() {
    if (this.messagingInstance) {
      this.messagingInstance()
    }
  }
}

export default new fcmService()
