import { acceptHMRUpdate, defineStore } from 'pinia'
import { computed, ref, toRefs } from 'vue'
import { useDeviceStore } from '/@/store/deviceStore'
import {
  Device,
  Paste,
  PasteDownloadStatus,
  PasteUploadStatus,
} from '/@/interfaces'

const sortPastesByDate = (pastes: Paste[]) => {
  return pastes.sort(
    (a: Paste | undefined, b: Paste | undefined) =>
      Number(new Date(a!.createdAt)) - Number(new Date(b!.createdAt)),
  )
}

const filterByUniquePastes = (pastes: Paste[]) => {
  return Array.from(new Set(pastes.map((paste) => paste.id))).map((id) =>
    pastes.find((paste) => paste.id === id),
  )
}

export const usePasteListStore = defineStore('pasteList', () => {
  const deviceStore = useDeviceStore()
  const { devices, selectedDeviceId, selectedDevice } = toRefs(deviceStore)
  const uploadingPastes = ref<Paste[]>([])

  const allPastes = computed(() => {
    if (devices.value) {
      return Object.values(devices.value || {}).flatMap((device: Device) =>
        Object.values(device.pastes || {}),
      )
    } else {
      return []
    }
  })

  const pasteList = computed(() => {
    let pastes: Paste[] | undefined

    if (selectedDeviceId.value === 'all') {
      pastes = allPastes.value
    } else {
      const currentSelectedDevicePastes = Object.values(
        selectedDevice.value?.pastes || {},
      )
      const pastesFilteredByDeviceSentFrom = allPastes.value.filter(
        (paste: Paste) => paste.deviceId === selectedDeviceId.value,
      )
      pastes = [
        ...currentSelectedDevicePastes,
        ...pastesFilteredByDeviceSentFrom,
      ]
    }

    if (!pastes) {
      return []
    }

    // Kinda hacky but we have to apply the RTDB ID inside the paste, so we
    // can access the paste path while deleting it.
    const pastesWithIds = Object.entries(pastes).map(([id, paste]) => {
      ;(paste as Paste).rtdbId = id
      return paste
    })

    const filteredPastes = uploadingPastes.value.filter(
      (paste: Paste) => paste.deviceId === selectedDeviceId.value,
    )

    const combinedPastes = [...pastesWithIds, ...filteredPastes]
    const uniquePastes = filterByUniquePastes(combinedPastes)

    return sortPastesByDate(uniquePastes as Paste[]).reverse()
  })

  const isPasteListEmpty = computed(() => pasteList.value.length === 0)

  const updateUploadingPasteStatus = (
    id: string,
    uploadStatus: PasteUploadStatus,
  ) => {
    const uploadingPasteToUpdate = uploadingPastes.value.find(
      (paste) => paste.id === id,
    )
    if (!uploadingPasteToUpdate) return
    const index = uploadingPastes.value.indexOf(uploadingPasteToUpdate)
    uploadingPasteToUpdate.uploadStatus = uploadStatus
    uploadingPastes.value.splice(index, 1, uploadingPasteToUpdate)
  }

  const updateEncryptingPasteStatus = (id: string, cryptAmount: number) => {
    const uploadingPasteToUpdate = uploadingPastes.value.find(
      (paste) => paste.id === id,
    )
    if (!uploadingPasteToUpdate) return
    const index = uploadingPastes.value.indexOf(uploadingPasteToUpdate)
    if (uploadingPasteToUpdate.uploadStatus) {
      uploadingPasteToUpdate.uploadStatus.cryptAmount = cryptAmount
    } else {
      return
    }
    uploadingPastes.value.splice(index, 1, uploadingPasteToUpdate)
  }

  const updateDecryptingPasteStatus = (id: string, cryptAmount: number) => {
    const downloadingPasteToUpdate = pasteList.value.find(
      (paste) => paste.id === id,
    )
    if (!downloadingPasteToUpdate || !downloadingPasteToUpdate.downloadStatus)
      return
    downloadingPasteToUpdate.downloadStatus.cryptAmount = cryptAmount
    downloadingPasteToUpdate.downloadStatus.decrypting = cryptAmount < 100
    downloadingPasteToUpdate.downloadStatus.isDownloading = false
    console.log(downloadingPasteToUpdate.downloadStatus)
  }

  const updateDownloadingPasteStatus = (
    id: string,
    downloadStatus: PasteDownloadStatus,
  ) => {
    const downloadingPasteToUpdate = pasteList.value.find(
      (paste) => paste.id === id,
    )
    if (!downloadingPasteToUpdate) return
    downloadingPasteToUpdate.downloadStatus = downloadStatus
  }

  const removePasteDownloadStatus = (id: string) => {
    const downloadingPasteToUpdate = pasteList.value.find(
      (paste) => paste.id === id,
    )
    if (!downloadingPasteToUpdate) return
    downloadingPasteToUpdate.downloadStatus = undefined
  }

  const removeUploadingPaste = (id: string) => {
    const i = uploadingPastes.value.find((item: Paste) => item.id === id)
    if (!i) return
    const index = uploadingPastes.value.indexOf(i)
    uploadingPastes.value.splice(index, 1)
  }

  const addNewPasteToPasteList = (paste: Paste, deviceId: string) => {
    const device = devices.value[deviceId]
    if (Array.isArray(device.pastes)) {
      device.pastes.push(paste)
    }
  }

  return {
    uploadingPastes,
    allPastes,
    pasteList,
    isPasteListEmpty,
    updateUploadingPasteStatus,
    removeUploadingPaste,
    updateDownloadingPasteStatus,
    removePasteDownloadStatus,
    updateEncryptingPasteStatus,
    updateDecryptingPasteStatus,
    addNewPasteToPasteList,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(usePasteListStore, import.meta.hot))
}
