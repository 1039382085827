// Global Types, across renderer and main process
// @ts-ignore -- cannot resolve import
import { type ManifestOptions } from 'vite-plugin-pwa/dist'

export enum PastaLocalStorageKeys {
  COPY_AUTO_SEND = 'pasta:copyAutoSend',
  CLIPBOARD_AUTO_RECEIVE = 'pasta:clipboardAutoReceive',
  ROOMS = 'pasta:rooms',
  SELECTED_DEVICE_ID = 'pasta:selectedDeviceId',
  AUTH_TOKEN = 'pasta:authToken',
  USER_ID = 'pasta:userId',
  USER_EMAIL = 'pasta:userEmail',
  DEVICES = 'pasta:devices',
  DEVICE = 'pasta:device',
  DEVICE_NAME = 'pasta:deviceName',
  APP_SETTINGS = 'pasta:appSettings',
  DESKTOP_SETTINGS = 'pasta:desktopSettings',
  APP_VIEW = 'pasta:view',
  SHOW_BETA_FORM = 'pasta:showBetaForm',
  AUTO_DOWNLOAD = 'pasta:autoDownload',
  AUTO_DOWNLOAD_LOCATION = 'pasta:autoDownloadLocation',
  CLICK_TO_COPY = 'pasta:clickToCopy',
  SHOW_NOTIFICATIONS = 'pasta:showNotifications',
  SHOW_PASTE_PREVIEW = 'pasta:showPastePreview',
  PROFILE_PICTURE_FILE = 'pasta:profilePictureFile',
  PROFILE_PICTURE_URL = 'pasta:profilePictureUrl',
  HAS_LOGGED_OUT = 'pasta:hasLoggedOut',
  // ENABLE_KEYBOARD_SHORTCUTS = 'pasta:enableKeyboardShortcuts',
  // TOGGLE_AUTO_SEND_KEYBIND = 'pasta:toggleCopyAutoSentBind',
  // TOGGLE_AUTO_RECEIVE_KEYBIND = 'pasta:toggleAutoReceiveBind',
}

export enum PastaTrayMenuIds {
  ONLINE_STATUS_CONNECTED = 'pasta_online_status-connected',
  ONLINE_STATUS_DISCONNECTED = 'pasta_online_status-disconnected',
  COPY_AUTO_SEND = 'pasta_copy_auto_send',
  CLIPBOARD_AUTO_RECEIVE = 'pasta_clipboard_auto_receive',
  AUTO_DOWNLOAD = 'pasta_auto_download',
  EXIT = 'pasta_exit',
  HISTORY_MESSAGE = 'pasta_history_message',
  HISTORY_SEPARATOR = 'pasta_history_separator',
  HISTORY_EVENT_MESSAGE = 'pasta_history_event_message',
}

export enum PastaTrayMenuText {
  CONNECTED_STATUS = 'Pasta is connected',
  DISCONNECTED_STATUS = 'Pasta is disconnected',
  COPY_AUTO_SEND = 'Auto Send on Copy',
  CLIPBOARD_AUTO_RECEIVE = 'Clipboard Auto Receive',
  AUTO_DOWNLOAD = 'Auto download files',
  EXIT = 'Quit Pasta',
}

export enum PastaIPCNames {
  testIPC = 'pasta-test-ipc',
  windowFocus = 'pasta-window-focus',
  clipboardWriteText = 'pasta-clipboard-write-text',
  clipboardWriteImage = 'pasta-clipboard-write-image',
  clipboardWriteFile = 'pasta-clipboard-write-file',
  copy = 'pasta-copy',
  updateTrayItem = 'pasta-update-tray-item',
  updateTrayConnected = 'pasta-update-tray-connected-status',
  toggleCopyAutoSend = 'pasta-toggle-copy-auto-send',
  toggleAutoDownload = 'pasta-toggle-auto-download',
  clipboardAutoSend = 'pasta-toggle-clipboard-auto-receive',
  receiveImage = 'pasta-receive-image',
  downloadFile = 'pasta-download-file',
  receiveCopiedFile = 'pasta-receive-copied-file',
  selectFolder = 'pasta-select-folder',
  selectFolderResponse = 'pasta-select-folder-response',
  windowEvents = 'pasta-window-events',
  quitAndUpdate = 'pasta-quit-and-update',
  notifyDesktopUpdate = 'pasta-notify-desktop-update',
  updateDownloadProgress = 'pasta-update-download-progress',
  MacOsIsTrustedAccessibility = 'pasta-macos-is-trusted-accessibility',
  MacOsPromptAccessibility = 'pasta-macos-prompt-accessibility',
  applyDesktopSettings = 'pasta-apply-desktop-settings',
  relaunchApp = 'pasta-exit-app',
  debugMode = 'pasta-debug-mode',
  requestCameraPermission = 'pasta-request-camera-permission',
  requestCameraPermissionResponse = 'pasta-request-camera-permission-response',
}

export interface PastaDesktopSetting {
  localStorageString: string
  menuItemString?: string
  storeName: string
  defaultValue: string | boolean
  value: string | boolean
}

export interface PastaWindowEvents {
  command: 'close' | 'minimize' | 'maximize'
}

// export enum AvailableKeyBindings {
//   TOGGLE_COPY_AUTO_SEND_BIND = 'toggleCopyAutoSentBind',
//   TOGGLE_AUTO_RECEIVE_BIND = 'toggleAutoReceiveBind',
// }

// export type AvailableKeyBindsArguments =
//   | AvailableKeyBindings.TOGGLE_AUTO_RECEIVE_BIND
//   | AvailableKeyBindings.TOGGLE_COPY_AUTO_SEND_BIND

// export interface KeyBindings {
//   copy: number[] | undefined
//   toggleCopyAutoSentBind: number[] | undefined
//   toggleAutoReceiveBind: number[] | undefined
// }
//
// export interface ActiveBindings {
//   copy: ioHook | undefined
//   toggleCopyAutoSentBind: undefined
//   toggleAutoReceiveBind: undefined
// }

export interface PwaOptions extends Partial<ManifestOptions> {
  share_target: {
    action: string
    method: string
    enctype: string
    params: {
      title: string
      text: string
      url: string
      files: [
        {
          name: string
          accept: string[]
        },
      ]
    }
  }
}
