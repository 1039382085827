import { acceptHMRUpdate, defineStore } from 'pinia'
import { computed, reactive, ref } from 'vue'
import type { Device } from '/@/interfaces'
import { hideFaviconNotification } from '/@/utils/toggleFaviconNotification'
import { PastaLocalStorageKeys } from '../../../../types'

// This function exists to get the device ID from local storage as early as
// possible so the store can filter the devices early on, so the sidebar
// doesn't show the current device also during startup.
const getThisDeviceFromLocalStorage = () => {
  return JSON.parse(localStorage.getItem(PastaLocalStorageKeys.DEVICE) || '{}')
    ?.id
}

export interface Devices {
  [key: string]: Device
}

export const useDeviceStore = defineStore('devices', () => {
  // Data

  const devices = ref<Devices>({})
  const thisDeviceId = ref(getThisDeviceFromLocalStorage() || '')
  const selectedDeviceId = ref('')
  const devicesWithUnseenPastes = ref<string[]>([])
  const showDeviceSettings = ref(false)
  const showCreateDevice = ref(false)
  const connectedDevices = reactive<Record<string, boolean>>({})

  // Computed

  const thisDevice = computed(() => devices.value[thisDeviceId.value])
  const selectedDevice = computed(() => devices.value[selectedDeviceId.value])
  const thisFcmId = computed(() => devices.value[selectedDeviceId.value]?.fcmId)

  const thisDeviceHasUnseenPastes = computed(() => {
    return devicesWithUnseenPastes.value?.includes(thisDeviceId.value)
  })

  const allDeviceChannel = computed(() => {
    return Object.values(devices.value).find((device) => device.type === 'all')
  })

  const devicesExcludingThisDevice = computed(() => {
    return Object.values(devices.value).filter(
      (device) => device.id !== thisDeviceId.value,
    )
  })

  const devicesExcludingAllDevices = computed(() => {
    return Object.values(devices.value).filter(
      (device) => device.type !== 'all',
    )
  })

  const devicesExcludingThisDeviceAndAll = computed(() => {
    return Object.values(devices.value).filter(
      (device) =>
        device.id !== thisDeviceId.value &&
        device.type !== 'all' &&
        !device.shouldLogOut,
    )
  })

  const onlineDevices = computed(() => {
    return Object.values(devices.value).filter((device) => device.connected)
  })

  // Methods

  const setActiveDevice = (deviceId: string) => {
    selectedDeviceId.value = deviceId
    localStorage.setItem(PastaLocalStorageKeys.SELECTED_DEVICE_ID, deviceId)
  }

  const updateConnectedDevices = (deviceId: string, connected: boolean) => {
    connectedDevices[deviceId] = connected
  }

  const addDeviceToUnseenPastes = (deviceId: string) => {
    devicesWithUnseenPastes.value.push(deviceId)
  }

  const removeDeviceFromUnseenPaste = (deviceId: string) => {
    if (devicesWithUnseenPastes.value.includes(deviceId)) {
      devicesWithUnseenPastes.value = devicesWithUnseenPastes.value.filter(
        (item) => item !== deviceId,
      )
      hideFaviconNotification()
    }
  }

  return {
    devices,
    thisDeviceId,
    selectedDeviceId,
    thisDevice,
    thisDeviceHasUnseenPastes,
    thisFcmId,
    devicesWithUnseenPastes,
    showDeviceSettings,
    showCreateDevice,
    connectedDevices,
    selectedDevice,
    allDeviceChannel,
    devicesExcludingThisDevice,
    devicesExcludingAllDevices,
    devicesExcludingThisDeviceAndAll,
    onlineDevices,
    setActiveDevice,
    updateConnectedDevices,
    addDeviceToUnseenPastes,
    removeDeviceFromUnseenPaste,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useDeviceStore, import.meta.hot))
}
