<template>
  <nav class="navbar" :class="{ 'navbar--blurred navbar--desktop': isDesktop }">
    <div class="navbar--left">
      <Notifications />
    </div>
    <div class="navbar--right" :class="{ 'navbar--blurred': !isDesktop }">
      <StorageUsage />
      <i-healthicons-s
        v-if="isStaging"
        v-tippy="{
          content: 'Staging',
          delay: 100,
          placement: 'bottom',
        }"
        class="navbar--right__staging"
      />
      <i-mdi-beta
        v-tippy="{
          content: 'Pasta is in Beta, expect data loss!',
          delay: 100,
          placement: 'bottom',
        }"
        class="navbar--right__alpha"
      />
      <i-feather-download-cloud
        v-if="showPwaUpdateButton || desktopUpdateReady"
        v-tippy="{
          content: 'Update available!',
          delay: 1000,
          placement: 'bottom',
          touch: false,
        }"
        class="navbar--right__update"
        role="button"
        @click="update"
      />
      <UpdateDownloadProgressCircle
        v-if="desktopUpdateDownloadProgress && !desktopUpdateReady"
        :progress="desktopUpdateDownloadProgress"
      />
      <!--      <i-ic-round-qr-code-2-->
      <!--        class="navbar&#45;&#45;right__qr-login"-->
      <!--        @click="openQRLogin"-->
      <!--        v-tippy="{-->
      <!--          content: 'Get login QR code',-->
      <!--          delay: [300, 250],-->
      <!--          placement: 'bottom',-->
      <!--          touch: false,-->
      <!--        }"-->
      <!--        role="button"-->
      <!--      />-->
      <i-feather-settings
        v-tippy="{
          content: 'Settings',
          delay: [1000, 250],
          placement: 'bottom',
          touch: false,
        }"
        class="navbar--right__settings"
        role="button"
        @mousedown="openAppSettings"
      />
      <LiveIndicator />
    </div>
  </nav>
</template>

<script setup lang="ts">
  import { ipcService, pwaService } from '../../services'
  import Notifications from './Notifications.vue'
  import isDesktop from '/@/utils/isDesktop'
  import UpdateDownloadProgressCircle from '/@/components/Loaders/UpdateDownloadProgressCircle.vue'
  import { useAppStateStore } from '/@/store/appState'
  import { storeToRefs } from 'pinia'
  import { directive as vTippy } from 'vue-tippy'
  import isStaging from '/@/utils/isStaging'

  const appState = useAppStateStore()
  const {
    showPwaUpdateButton,
    desktopUpdateDownloadProgress,
    desktopUpdateReady,
  } = storeToRefs(appState)

  const openAppSettings = () => {
    appState.showAppSettings = true
  }

  const update = () => {
    if (isDesktop && desktopUpdateReady.value) {
      ipcService.handleClickOnDesktopUpdateButton()
    } else {
      pwaService.updatePwa()
    }
  }
</script>

<style lang="scss" scoped>
  .navbar {
    position: absolute;
    width: 100%;
    right: 0;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 5;

    &--blurred {
      backdrop-filter: blur(4px);
      background-color: #22222229;
      transition: background-color 0.2s ease-in-out;
    }

    &--desktop {
      -webkit-user-select: none;
      -webkit-app-region: drag;
      cursor: pointer !important;
      width: calc(100% - 8px);
      right: 8px;
      z-index: 10;

      &:hover {
        background-color: #48484829;
      }
    }

    &--left,
    &--right {
      height: 100%;
      display: flex;
      align-items: center;
      padding: 0 20px 0 12px;
    }

    &--right {
      border-bottom-left-radius: 14px;
      -webkit-app-region: none;
    }

    &--left {
      p {
        font-weight: 600;
        font-size: 26px;
      }
    }

    &--right {
      &__settings,
      //&__qr-login,
      &__update,
      &__view-container,
      &__alpha,
      &__staging {
        height: 37px;
        width: 37px;
        border-radius: 100%;
        cursor: pointer;
        transition: background-color 100ms ease-in-out;
        z-index: 1;

        &:focus {
          outline: none;
        }

        &:hover {
          background-color: $background-paste;
        }

        &:active {
          background-color: #000000;
        }
      }

      //&__qr-login {
      //  padding: 7px;
      //  //margin-right: 7px;
      //  //margin-left: 6px;
      //  height: 37px;
      //  width: 37px;
      //}

      &__view-container {
        .pasta-icon {
          height: 37px;
          width: 37px;
        }
      }

      &__alpha {
        height: 33px;
        width: 33px;
        padding: 6px;
      }

      &__staging {
        padding: 5px;
      }

      &__update,
      &__view {
        padding: 8px;

        &:focus {
          outline: none;
        }
      }

      &__settings {
        margin-right: 9px;
        padding: 9px;

        &:hover {
          animation: spin 1.4s linear infinite;
        }
      }
    }
  }

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
</style>
