import { PastaLocalStorageKeys } from '../../../../types'
import { applySettings } from '/@/utils/settings/applySettings'
import {
  deviceService,
  idbService,
  ipcService,
  loginService,
  RTCService,
  sealdService,
  userService,
} from '/@/services/index'
import pwaService from '/@/services/pwaService'
import { checkClipboardPermission, handlePaste } from '/@/utils/clipboardUtils'
import { initAutoRead } from '/@/utils/AutoRead'
import isMobile from '/@/utils/isMobile'
import firebaseConfig from '/@/config/firebaseConfig'
import isDesktop from '/@/utils/isDesktop'
import { useActiveElement, useEventListener } from '@vueuse/core'
import { watch } from 'vue'
import handlePasteVisibility from '/@/utils/handlePasteVisibility'
import { databaseService } from '/@/services'
import offlineModeService from '/@/services/offlineModeService'
import { defaultSettings } from '/@/constants/defaultSettings'
import { initializeApp } from 'firebase/app'
import { initializeAppCheck, ReCaptchaV3Provider } from 'firebase/app-check'
import { useDeviceStore } from '/@/store/deviceStore'
import { useUserStore } from '/@/store/user'
import { useAppStateStore } from '/@/store/appState'
import isDev from '/@/utils/isDev'

const setMobileFullHeight = () => {
  const vh = window.innerHeight * 0.01
  document.documentElement.style.setProperty('--vh', `${vh}px`)
}

const setFirebaseInstance = () => {
  const app = initializeApp(firebaseConfig)
  if (isDev) return
  initializeAppCheck(app, {
    provider: new ReCaptchaV3Provider(import.meta.env.VITE_RECAPTCHA_SITE_KEY),
    isTokenAutoRefreshEnabled: true,
  })
}

const setUpDevicesLocally = async () => {
  const deviceStore = useDeviceStore()
  const appState = useAppStateStore()
  const currentRoomId = localStorage.getItem(
    PastaLocalStorageKeys.SELECTED_DEVICE_ID,
  )
  if (!currentRoomId) return
  deviceStore.setActiveDevice(currentRoomId)
  try {
    const devices = await idbService.getPastaData()
    if (!devices || Object.keys(deviceStore.devices).length > 0) return
    deviceStore.devices = devices
    appState.loadingPastes = false
  } catch (err) {
    console.error(err)
  }
}

const setSettings = () => {
  const localSettings = localStorage.getItem(PastaLocalStorageKeys.APP_SETTINGS)
  const settingsString = localSettings
    ? JSON.parse(localSettings)
    : defaultSettings
  applySettings(settingsString)
}

const listenForWindowFocus = () => {
  const deviceStore = useDeviceStore()
  const appState = useAppStateStore()
  useEventListener(window, 'blur', () => {
    appState.windowFocused = false
  })

  useEventListener(window, 'focus', () => {
    appState.windowFocused = true
    if (deviceStore.thisDeviceHasUnseenPastes) {
      deviceStore.removeDeviceFromUnseenPaste(deviceStore.selectedDeviceId)
    }
  })
}

class startupService {
  private isInUserActionRoute = false

  public async init(): Promise<void> {
    const appState = useAppStateStore()
    setFirebaseInstance()
    // Showing or hiding authentication happens in here
    loginService.authorizeFirebase()
    await this.preAuthSetup()
    appState.showLoadingOverlay = false
    if (this.isInUserActionRoute) return
  }

  public async preAuthSetup() {
    // Set up listener for shared files via web share target API
    setSettings()
    listenForWindowFocus()
    if (isMobile.value) {
      setMobileFullHeight()
      window.addEventListener('resize', () => {
        setMobileFullHeight()
      })
    }
    // Set up PWA update watcher service
    pwaService.pwaUpdateService()

    if (isDesktop) {
      ipcService.startIPCListener()
    }

    if (!navigator.onLine && !import.meta.env.VITE_DEV_EMULATORS) {
      offlineModeService.setupOfflineDetector()
    }
    if (await userService.handleUserActionsRoute()) {
      // This prevents firebase from doing its login authorization
      this.isInUserActionRoute = true
    }
  }

  async onLoginSuccessViaQRCode(serializedEncryptionId: string, path: string) {
    await setUpDevicesLocally()
    const user = await databaseService.getUser()

    await sealdService.init(user.dbKey, user.dbSessionId)
    await sealdService.importIdentity(serializedEncryptionId)
    const currentUser = await sealdService.currentAccountInfo()

    if (!currentUser) {
      console.error('no current user')
      return
    }
    await Promise.all([
      sealdService.setEncryptionSession(user.encryptionSessionId),
      databaseService.listenForUserChanges(),
      databaseService.deleteQrLoginData(path),
      this.postAuthSetup(),
    ])
  }

  public async onLoginSuccess(): Promise<void> {
    const userStore = useUserStore()
    const deviceStore = useDeviceStore()
    const appState = useAppStateStore()

    await setUpDevicesLocally()

    const user = await databaseService.getUser()

    if (user) {
      userStore.user = user
    } /*else if (isDev) {
      // This triggers the email verification because the user creation
      // hook exists in the verifyEmail component, for use with fb emulators
      // because fb emulators can't supply email auth codes correctly.
      appState.showEmailVerification = true
      return
    }*/
    // If the user has no encryption key, prompt the user to create a key
    // by opening the modal and returning from here, once the key is
    // created, this onLoginSuccess function will get called again from
    // inside showEncryptionKey modal.
    if (!user || user.hasEncryptionKey === false) {
      appState.showEncryptionKey = true
      return
    }
    // Initialize Seald, with dbKey, dbSessionId and the encryptionSessionId
    // stored on the user, if it's not stored on the user, then the above is
    // run to prompt the user to set up the encryption and user key first.
    await sealdService.init(user.dbKey, user.dbSessionId)
    const currentUser = await sealdService.currentAccountInfo()

    if (!currentUser) {
      appState.showEncryptionKeyInput = true
      return
    }
    // Check for devices, if there is only 1 device (the 'all devices channel'), prompt the user to create one
    const devices = await databaseService.getDevices()
    const deviceKeys = Object.keys(devices || {})

    if (devices && deviceKeys.length > 1) {
      deviceStore.devices = devices
      // Initiate RTC connections for all connected devices
      // RTCService.connectToDevices(Object.values(devices))
      // RTCService.reconnectTimer()
    } else {
      appState.showCreateDevice = true
      return
    }

    await Promise.all([
      sealdService.setEncryptionSession(user.encryptionSessionId),
      databaseService.listenForDeviceChanges(),
      databaseService.listenForUserChanges(),
      this.postAuthSetup(),
    ])
  }

  public async postAuthSetup(): Promise<void> {
    const userStore = useUserStore()
    const deviceStore = useDeviceStore()

    const appState = useAppStateStore()
    if (!userStore.loggedIn) return

    // Set up device
    await deviceService.initDevice()

    // Get Clipboard API permission for read and write.
    await checkClipboardPermission()

    // Add event listener for paste events
    const activeElement = useActiveElement()
    watch(activeElement, (el: HTMLElement | null | undefined) => {
      handlePasteVisibility(el)
    })
    window.addEventListener('paste', async (event: ClipboardEventInit) => {
      if (!appState.allowPaste || appState.pasteInputFocused) return
      await handlePaste(event)
    })

    if (!window.isDesktop) initAutoRead()

    // If a device is set up already, this will run, otherwise it will be
    // run from the deviceService.initDevice function.
    if (deviceStore.thisDeviceId) {
      await this.postDeviceSetup()
    }

    // pwaService.pwaReceiveShare()
    // Ask for permission to enable notifications
    // await inAppNotificationService.requestNotificationPermission()
  }

  async postDeviceSetup() {
    if (!import.meta.env.VITE_DEV_EMULATORS) {
      offlineModeService.setupOfflineDetector()
    }

    await databaseService.listenForDeviceChanges()

    // Start listening for RTC Connection Requests, requires deviceID
    await RTCService.getStunCreds()
    await databaseService.listenForRTCConnectionRequests()
    await databaseService.listenForRTCICECandidates()
  }
}

export default new startupService()
