<template>
  <transition-group name="faÏde">
    <BetaForm
      v-if="showBetaForm"
      :on-has-account="() => (showBetaForm = false)"
    />
    <Login v-if="showLoginDialog" />
    <Signup v-if="showSignupDialog" />
    <EncryptionKey v-if="showEncryptionKey" />
    <EncryptionKeyInput v-if="showEncryptionKeyInput" />
    <VerifyEmail v-if="showEmailVerification" />
    <AskToVerifyEmail v-if="showAskForEmailVerification" />
    <ResetPassword v-if="showPasswordReset" />
    <CreateDevice v-if="showCreateDevice" />
    <FileDrop v-if="showFileDrop" :toggle-file-drop="toggleFileDrop" />
    <LoadingOverlay v-if="showLoadingOverlay" />
    <UpdateOverlay v-if="showUpdateOverlay" />
    <MainSettings v-if="showAppSettings" />
    <RoomSettings v-if="showDeviceSettings" />
    <ShareQR v-if="showShareQR" />
    <LoginQR v-if="showLoginQR" />
    <QRScanner v-if="showQRScanner" />
    <LogMeInQR v-if="showLogMeInQR" />
    <ConfirmDeleteUser v-if="showDeleteUser" />
  </transition-group>
</template>

<script setup lang="ts">
  import {
    AskToVerifyEmail,
    EncryptionKey,
    EncryptionKeyInput,
    FileDrop,
    LoadingOverlay,
    Login,
    LogMeInQR,
    MainSettings,
    QRScanner,
    ResetPassword,
    RoomSettings,
    ShareQR,
    Signup,
    UpdateOverlay,
    VerifyEmail,
  } from '/@/components/asyncComponents'
  import { storeToRefs } from 'pinia'
  import { useAppStateStore } from '/@/store/appState'
  import { useDeviceStore } from '/@/store/deviceStore'
  import { useUserStore } from '/@/store/user'
  import { computed } from 'vue'

  // Dependencies

  const appState = useAppStateStore()
  const deviceStore = useDeviceStore()
  const userStore = useUserStore()

  // Data

  const {
    showSignup,
    showAppSettings,
    showLoadingOverlay,
    showDeleteUser,
    showShareQR,
    showLoginQR,
    showLogMeInQR,
    showQRScanner,
    showCreateDevice,
    showEncryptionKey,
    showEncryptionKeyInput,
    showUpdateOverlay,
    showEmailVerification,
    showPasswordReset,
    showAskForEmailVerification,
    showAuthentication,
    showMaintenanceMode,
    showBetaForm,
    showFileDrop,
  } = storeToRefs(appState)
  const { loggedIn } = storeToRefs(userStore)
  const { showDeviceSettings } = storeToRefs(deviceStore)

  // Computed

  const showLoginDialog = computed(
    () =>
      showAuthentication.value &&
      !showMaintenanceMode.value &&
      !showQRScanner.value &&
      !showLogMeInQR.value &&
      !loggedIn.value,
  )

  const showSignupDialog = computed(
    () =>
      showSignup.value &&
      !showAuthentication.value &&
      !showEmailVerification.value &&
      !showPasswordReset.value,
  )

  // Methods

  const toggleFileDrop = () => {
    if (appState.isOfflineMode) return
    showFileDrop.value = !showFileDrop.value
  }
</script>

<style scoped lang="scss"></style>
