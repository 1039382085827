// This detects a newly created paste from an incoming RTB snapshot

import isNewerThanTenSecondsOld from '/@/utils/time/isNewerThanTenSecondsOld'
import { Paste } from '/@/interfaces'
import { Devices } from '/@/store/deviceStore'

const getNewestPaste = (rooms: Devices) => {
  const allPastesFromAllRooms = Object.values(rooms).flatMap((rooms) => {
    if (!rooms.pastes || rooms.pastes.length === 0) return
    return Object.values(rooms.pastes).map((pastes) => pastes)
  })
  return allPastesFromAllRooms?.find((paste: undefined | Paste) => {
    if (!paste) return
    return isNewerThanTenSecondsOld(5, paste?.createdAt)
  })
}

export default getNewestPaste
