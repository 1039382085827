import { registerSW } from 'virtual:pwa-register'
import firebaseUploader from '../utils/firebase/firebaseUploader'
import { showNotification } from '../utils/notificationUtils'
import devlog from '../utils/log'
import { databaseService } from '/@/services/index'
import isMobile from '/@/utils/isMobile'
// import makeSureRoomSelected from '/@/utils/makeSureRoomSelected'
import isOverUsageLimit from '/@/utils/files/isOverUsageLimit'
import { useUserStore } from '/@/store/user'
import { useAppStateStore } from '/@/store/appState'

const swCheckUpdateIntervalMS = 10000

class pwaService {
  private updateServiceWorker: any | null = null
  private broadcastChannel = new BroadcastChannel('pwa-channel')

  public pwaReceiveShare(): void {
    devlog('app', 'Share', 'Running PWA Share receiver')
    this.broadcastChannel.postMessage({
      type: 'message',
      action: 'get_shared_file',
    })

    this.broadcastChannel.onmessage = async (event) => {
      devlog('pwa', 'pwaService', 'CLIENT on message', event)
      // Temp hack, TODO: make a room selector UI like slack before share
      // makeSureRoomSelected()
      const shareData = event.data.payload?.[0]
      if (event.data.type === 'file' && shareData) {
        for (const file of event.data.payload) {
          if (isOverUsageLimit([file])) {
            alert(
              'File to large! You will go over your storage usage limit, please delete some files first!',
            )
            return
          }
          await firebaseUploader(file, file.type)
        }
      }

      if (event.data.type === 'text' && shareData) {
        await databaseService.createPaste(shareData, 'text/string')
      }
    }
  }

  public pwaUpdateService(): void {
    const userStore = useUserStore()
    const appState = useAppStateStore()
    devlog('pwa', 'ServiceWorker', 'Setting up!')
    const updateSW = registerSW({
      async onNeedRefresh() {
        devlog('pwa', 'ServiceWorker', 'PWA Needs refresh')
        // If not logged in, just update immediately.
        if (!userStore.loggedIn) await updateSW(true)
        // Show the update button in the navbar
        appState.showPwaUpdateButton = true
        // Show update notification unless on mobile.
        if (isMobile) return
        await showNotification(
          'Pasta update available, tap to update!',
          updateSW,
        )
      },
      onOfflineReady() {
        devlog('pwa', 'ServiceWorker', 'Offline ready!')
        appState.isOfflineReady = true
      },
      async onRegisteredSW(_, r) {
        r &&
          setInterval(() => {
            r.update()
          }, swCheckUpdateIntervalMS)
      },
    })
    this.updateServiceWorker = async () => await updateSW(true)
  }

  public async updatePwa(): Promise<void> {
    const appState = useAppStateStore()
    if (this.updateServiceWorker) {
      appState.showUpdateOverlay = true
      await this.updateServiceWorker(true)
    } else {
      throw new Error(
        'Could not update PWA! updateServiceWorker() not initialized',
      )
    }
    // Fallback to reload the page if nothing happens after updating.
    setTimeout(() => window.location.reload(), swCheckUpdateIntervalMS)
  }
}

export default new pwaService()
