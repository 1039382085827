import devlog from '../utils/log'
import fcmService from './fcmService'
import { PastaLocalStorageKeys } from '../../../../types'
import { Device } from '/@/interfaces'
import { databaseService, RTCService, startupService } from '/@/services/index'
import { requestAndSetNotificationPermission } from '/@/utils/notificationUtils'
import isDesktop from '/@/utils/isDesktop'
import logout from '/@/utils/logout'
import { useAppStateStore } from '/@/store/appState'
import { useDeviceStore } from '/@/store/deviceStore'

class deviceService {
  public async initDevice(): Promise<void> {
    const appState = useAppStateStore()
    fcmService.initFCMServiceWorker()
    const deviceStore = useDeviceStore()
    const localDevice: Device | null = JSON.parse(
      localStorage.getItem(PastaLocalStorageKeys.DEVICE) || '{}',
    )

    const localDeviceExistsInDevicesList = Object.values(
      deviceStore.devices,
    ).find((device: Device) => device.id === localDevice?.id)

    // If a local device is present and matches a saved device, set it up.
    if (localDevice && !!localDeviceExistsInDevicesList) {
      await this.setupExistingDevice(localDevice)
    } else {
      // Prompt the user to create a new device channel
      appState.showCreateDevice = true
    }
  }

  public async setupExistingDevice(localDevice: Device) {
    const deviceStore = useDeviceStore()
    const notificationPermission = await requestAndSetNotificationPermission()
    const notificationsGranted = notificationPermission === 'granted'

    if (localDevice.shouldLogOut) {
      await this.removeDevice(localDevice, true)
      await logout()
    }

    deviceStore.thisDeviceId = localDevice.id!

    // If there's an FCM ID present, start the FCM service worker.
    if (notificationsGranted && localDevice.fcmId && !isDesktop) {
      fcmService.initForegroundFCM()
    } else if (notificationsGranted && !localDevice.fcmId && !isDesktop) {
      await this.setDeviceFCMId(localDevice)
    }
    // Set the current device to the local device in store and local storage.
    localStorage.setItem(
      PastaLocalStorageKeys.DEVICE,
      JSON.stringify(localDevice),
    )
    devlog('init', 'Device', 'Set up existing device')
  }

  // Used when selecting an existing device to use as the current device in
  // 'CreateDevice.vue'
  public async selectExistingDevice(localDevice: Device) {
    const deviceStore = useDeviceStore()

    if (localDevice.shouldLogOut) {
      await this.removeDevice(localDevice, true)
      await logout()
    }

    deviceStore.thisDeviceId = localDevice.id!

    // Set the current device to the local device in store and local storage.
    localStorage.setItem(
      PastaLocalStorageKeys.DEVICE,
      JSON.stringify(localDevice),
    )
    devlog('init', 'Device', 'Set up existing device')
  }

  public async setDeviceFCMId(device: Device) {
    device.fcmId = await fcmService.getFCMToken()
    await databaseService.editDevice(device)
    fcmService.initFCMServiceWorker()
    fcmService.initForegroundFCM()
  }

  public async createNewDevice(title: string, platform: string) {
    let fcmId = null
    // const deviceStore = useDeviceStore()
    const notificationPermission = await requestAndSetNotificationPermission()
    const notificationsGranted = notificationPermission === 'granted'

    if (notificationsGranted && !isDesktop) {
      fcmId = await fcmService.getFCMToken()
    }

    const addedDevice = await databaseService.createDevice(
      title,
      platform,
      fcmId || '',
    )

    if (!addedDevice || !addedDevice.id) {
      console.error('Failed to add device')
      return
    }

    // newDevice.id = addedDevice.key
    // deviceStore.thisDeviceId = newDevice.id

    localStorage.setItem(
      PastaLocalStorageKeys.DEVICE,
      JSON.stringify(addedDevice),
    )
    //
    // if (notificationsGranted && newDevice.fcmId) {
    //   fcmService.initFCMServiceWorker()
    //   fcmService.initForegroundFCM()
    // }

    devlog('init', 'Device', 'Set up a new device')

    // await databaseService.createRoom(name)

    await startupService.postDeviceSetup()
  }

  public async editDevice(
    device: Device,
    title: string,
    saveLocalDevice = false,
  ) {
    device.title = title
    if (saveLocalDevice) {
      localStorage.setItem(PastaLocalStorageKeys.DEVICE, JSON.stringify(device))
    }
    await databaseService.editDevice(device)
  }

  // Instead of just deleting the device, we mark it for deletion. Backend
  // cron job will delete it after 60 days. If a user logs in with a device
  // that is marked for deletion, it will be removed from the database after
  // logging the user out.
  public async markDeviceForDeletion(device: Device) {
    device.shouldLogOut = true
    await databaseService.editDevice(device)
  }

  public async removeDevice(device: Device, removeLocalDevice = false) {
    if (removeLocalDevice) localStorage.removeItem(PastaLocalStorageKeys.DEVICE)
    // await databaseService.removeDevice(device)
  }
}

export default new deviceService()
