<template>
  <Overlay>
    <div
      class="pasta-authentication pasta-card pasta-card--dark pasta-card--padded pasta-card--margined"
    >
      <div class="pasta-authentication__header-container">
        <SettingsCategoryButton
          class="pasta-authentication__header"
          :selected="true"
        >
          <p>Set up this device</p>
        </SettingsCategoryButton>
      </div>
      <transition name="viewFade">
        <div v-if="errorMessage" class="pasta-reset-error">
          {{ errorMessage }}
        </div>
      </transition>
      <p class="pasta-card__text">
        Use an existing device or logging in on a new device?
      </p>
      <br />
      <div v-if="!success" class="pasta-login-inputs">
        <div v-if="deviceList.length > 1">
          <select class="pasta-login-inputs__device" @change="selectDevice">
            <option
              v-for="device in deviceList"
              :value="device.id"
              :key="device.id"
            >
              {{ device.title }}
            </option>
          </select>
        </div>
        <div>
          <input
            v-if="!hasSelectedDevice"
            ref="nameInputRef"
            v-model="name"
            type="text"
            autofocus
            class="pasta-login-inputs__email"
            placeholder="Name"
            @keydown="resetErrors"
            maxlength="50"
          />
          <input
            v-model="type"
            type="text"
            class="pasta-login-inputs__email"
            placeholder="Type"
            autofocus
            @keydown="resetErrors"
            maxlength="30"
            :disabled="hasSelectedDevice"
          />
        </div>
      </div>
      <div class="pasta-login-buttons">
        <PsButton
          class="pasta-login-buttons__signup"
          text="Continue"
          :callback="
            hasSelectedDevice ? onDoneSelectingDevice : onDoneCreatingDevice
          "
          type="large primary"
          :disabled="!enableSave"
        />
        <PsButton
          class="pasta-login-buttons__signup"
          :text="'Log out'"
          :callback="cancel"
          type="large secondary"
        />
      </div>
    </div>
  </Overlay>
</template>

<script setup lang="ts">
  import { ref, computed, toRefs, onMounted } from 'vue'
  import platform from '../../../utils/devices/platform'
  import { deviceService } from '/@/services'
  import { useAppStateStore } from '/@/store/appState'
  import isMobile from '/@/utils/isMobile'
  import { useDeviceStore } from '/@/store/deviceStore'
  import { Device } from '/@/interfaces'

  const NEW_DEVICE = 'new'

  // Dependencies

  const appState = useAppStateStore()
  const deviceStore = useDeviceStore()
  const { devicesExcludingAllDevices } = toRefs(deviceStore)

  // Data

  const nameInputRef = ref()

  const name = ref(`${platform.browser.name} on ${platform.os.name}`)
  const type = ref(`${isMobile ? 'Mobile' : 'Desktop'}`)

  const selectedDevice = ref<Device>()

  const isLoading = ref(false)
  const success = ref(false)
  const errorMessage = ref()
  const errorCode = ref()

  // Computed

  const enableSave = computed(() => {
    return !!name.value && !!type.value && !isLoading.value
  })

  const deviceList = computed(() => {
    return [
      { title: 'Add new device', id: NEW_DEVICE } as Device,
      ...devicesExcludingAllDevices.value,
    ]
  })

  const hasSelectedDevice = computed(
    () => !!selectedDevice.value && selectedDevice.value.id !== NEW_DEVICE,
  )

  // Methods

  const selectDevice = (event: Event) => {
    const { value: deviceId } = event.target as HTMLSelectElement
    selectedDevice.value = deviceList.value.find(
      (device) => device.id === deviceId,
    )
    if (deviceId === NEW_DEVICE) {
      name.value = ''
      type.value = ''
      nameInputRef.value?.focus()
      return
    }
    name.value = selectedDevice.value?.title || ''
    type.value = selectedDevice.value?.type || ''
  }

  const resetErrors = () => {
    errorCode.value = null
    errorMessage.value = null
  }

  const onDoneCreatingDevice = async () => {
    isLoading.value = true
    await deviceService.createNewDevice(name.value, type.value)
    isLoading.value = false
    appState.showCreateDevice = false
  }

  const onDoneSelectingDevice = async () => {
    if (!selectedDevice.value || selectedDevice.value.id === NEW_DEVICE) return
    isLoading.value = true
    await deviceService.selectExistingDevice(selectedDevice.value)
    isLoading.value = false
    appState.showCreateDevice = false
  }

  const cancel = () => {}

  // Hooks

  onMounted(() => {
    selectDevice({
      target: {
        value: NEW_DEVICE,
      },
    })
  })
</script>

<style lang="scss" scoped>
  @import '../../../css/authentication';
</style>
